import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";

import { refs } from "../../repos/constants";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";

export const ServiceEntryCustomer = ({
                                      customerId,
                                      customerName, onCustomerNameChanged,
                                      companyName, onCompanyNameChanged,
                                      businessTypeId, onBusinessTypeIdChanged,
                                      businessTypeName, 
                                      address, onAddressChanged,
                                      contactName, onContactNameChanged,
                                      contactNo, onContactNoChanged,
                                      nrc, onNrcChanged,
                                      designation, onDesignationChanged,
                                      isLoading, isEditable, isSubmitting, hasErrors, formErrors,
                                      messageSection, children,
                                    }) => {
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);

  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoadingBusinessTypes(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response.data))
      .finally(() => setIsLoadingBusinessTypes(false));
  }

  const isFormControlsDisabled = () => {
    return isLoading || isSubmitting;
  }

  return (
    <div className={"form-section"}>
      <h2>Customer & Contact Details</h2>

      {messageSection}

      <div className={"entry-form service-profile-form"}>
        <div className={"form-label"}>
          <label>
            Customer Name:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label>
            Company:
          </label>
        </div>

        <div className={"form-label"}>
          <label>
            Business Type:<span className={"error-message"}>*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label>
            Address:
          </label>
        </div>

        <div className={"form-input"}>
          {!isEditable && 
            <ReadonlyField>
              {customerName}
            </ReadonlyField>
          }
          {isEditable &&
            <>
              <input type={"text"} id={"customerName"} className={"form-control"}
                    autoComplete={"off"} maxLength={100} disabled={isFormControlsDisabled()}
                    value={customerName} onChange={onCustomerNameChanged} />
              <FieldErrorMessage visible={hasErrors} message={formErrors["customerName"]} />
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && 
            <ReadonlyField>
              <NullBlankValue value={companyName} />
            </ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"companyName"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isFormControlsDisabled()}
                   value={companyName} onChange={onCompanyNameChanged} />
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>
              {businessTypeName}
            </ReadonlyField>
          }
          {isEditable &&
            <select name={"businessTypeName"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                    value={businessTypeId} onChange={onBusinessTypeIdChanged}>
              {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>
              <NullBlankValue value={address} />
            </ReadonlyField>
          }
          {isEditable &&
            <textarea id={"address"} className={"form-control"} rows={3} disabled={isFormControlsDisabled()}
                      value={address} onChange={onAddressChanged} maxLength={300}>
            </textarea>
          }
        </div>

        <div className={"form-label-r"}>
          <label>
            Contact Name:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label-r"}>
          <label>
            Contact No:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label-r"}>
          <label>
            Contact NRC:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label>
            Contact Designation:
          </label>
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>
              {contactName}
            </ReadonlyField>
          }
          {isEditable &&
            <>
              <input type={"text"} id={"contactName"} className={"form-control md-field"} 
                     autoComplete={"off"} disabled={isFormControlsDisabled()} maxLength={100}
                     value={contactName} onChange={onContactNameChanged} />
              <FieldErrorMessage visible={hasErrors} message={formErrors["contactFullName"]} />
            </>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>
              {contactNo}
            </ReadonlyField>
          }
          {isEditable &&
            <>
              <input type={"text"} id={"contactNo"} className={"form-control md-field"} 
                     autoComplete={"off"} disabled={isFormControlsDisabled()} maxLength={50}
                     value={contactNo} onChange={onContactNoChanged} />
              <FieldErrorMessage visible={hasErrors} message={formErrors["contactNo"]} />
            </>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>
              <NullBlankValue value={nrc} />
            </ReadonlyField>
          }
          {isEditable && 
            <input type={"text"} id={"nrc"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={50}
                   value={nrc} onChange={onNrcChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>
              <NullBlankValue value={designation} />
            </ReadonlyField>
          }
          {isEditable && 
            <input type={"text"} id={"designation"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={50}
                   value={designation} onChange={onDesignationChanged} />
          }
        </div>
      </div>

      {children}
    </div>
  )
}