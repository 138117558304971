import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { authService } from "../../repos/apiServices";
import { authService as authService2 } from "../../repos/apiServices2";

import { Footer } from "../shared/Footer";

import './LoginPage.css';

export const LoginPage = () => {

  //#region States
  const [login, setLogin] = useState({ username: '', password: '' });
  const [errors, setErrors] = useState({ hasErrors: false, usernameError: '', passwordError: '', loginError: '' })
  const [isSubmitting, setIsSubmitting] = useState(false);

  const auth = useAuth()
  const navigate = useNavigate();
  const location = useLocation();

  if (auth.hasUserSession()) {
    return <Navigate to='/' state={{ path: location.pathname }} />
  }
  //#endregion

  //#region Control handlers
  const onUsernameChanged = (ev) => {
    setLogin({ ...login, username: ev.target.value });
  }

  const onPasswordChanged = (ev) => {
    setLogin({ ...login, password: ev.target.value });
  }

  const onLoginSubmitted = (ev) => {
    ev.preventDefault();
    setIsSubmitting(true);
    let errors = {
      hasErrors: false,
      usernameError: '',
      passwordError: '',
      loginError: '',
    }
    setErrors(errors);
    if (login.username.trim() === '') {
      errors.hasErrors = true;
      errors.usernameError = 'Login username is required.';
    } else if (login.username.trim().length < 4) {
      errors.hasErrors = true;
      errors.usernameError = 'Login username has to be at least 4 characters long.';
    }
    if (login.password.trim() === '') {
      errors.hasErrors = true;
      errors.passwordError = 'Password is required.';
    } else if (login.password.trim().length < 4) {
      errors.hasErrors = true;
      errors.passwordError = 'Password has to be at least 4 characters long.';
    }

    if (errors.hasErrors) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    let _authToken = null;
    authService.tokenizedLogin({ username: login.username, password: login.password })
      .then((response) => {
        _authToken = response.data;

        return authService.fetchUserInfo(login.username, _authToken.access);
      })
      .then((response) => {
        let userInfo = response.data;
        auth.login(_authToken);
        auth.saveUserInfoWithRouteGrants(userInfo);

        return authService2.fetchUserInfo(login.username, _authToken.access);
      })
      .then((response) => {
        let userInfo2 = response.data;
        auth.appendUserRouteGrants(userInfo2['moduleGrants'], userInfo2['routeGrants']);

        navigate('/');
      })
      .catch((error) => {
        errors.hasErrors = true;
        auth.logout();
        if (error.response) {
          let _responseStatus = error.response.status;
          let _responseData = error.response.data;
          if (_responseStatus === 400) {
            errors.loginError = _responseData['error'];
          }
          if (_responseStatus === 401) {
            errors.loginError = 'There is no account registered with these login credentials.';
          }
        } else {
          errors.loginError = 'There was a problem trying to reach the server.';
        }
        setErrors(errors);
      })
      .finally(() => setIsSubmitting(false));
  }
  //#endregion

  //#region Render
  return (
    <main className="login-container" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/login-background.png` }}>
      <div className="login-form-container">
        <div className="login-form-left"></div>
        <div className="login-form">
          <h1>LOGIN</h1>
          <div className="info-message">
            Enter your credentials below:
          </div>
          <form onSubmit={onLoginSubmitted} autoComplete={"off"}>
            <div className="form-field">
              <input type="textbox" className="form-control" id="username" name="username" placeholder="Login username"
                     autoComplete={"off"} autoFocus minLength={4}
                     value={login.username} onChange={onUsernameChanged} />
            </div>
            <div className="form-field">
              <input type="password" className="form-control" id="password" name="password" placeholder="Password"
                     autoComplete={"off"} minLength={4}
                     value={login.password} onChange={onPasswordChanged} />
            </div>
            {errors.hasErrors &&
              <div className="error-message validation-message">
                {errors.usernameError && <div>{errors.usernameError}</div>}
                {errors.passwordError && <div>{errors.passwordError}</div>}
                {errors.loginError && <div>{errors.loginError}</div>}
              </div>
            }
            <div>
              <button type="submit" className="btn btn-primary btn-lg w-100 login-button" name="submit"
                      disabled={isSubmitting}>
                {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmitting && <i className="fa-solid fa-lock"></i>}
                <span>Login</span>
              </button>
            </div>
          </form>
        </div>
        <div className="login-form-right"></div>
      </div>

      <Footer fullWidth={true} />
    </main>
  )
  //#endregion

}
