import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { authService as authService2 } from "../../repos/apiServices2";
import { inventoryServices } from "../../repos/apiServices";
import { technicalServices } from "../../repos/apiServices2";
import { customerServices as customerServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";
import { getRegionDisplayByName } from "../../repos/utilities";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { ServiceEntryCustomer } from "./ServiceEntryCustomer";
import { JobStatusBadge } from "./JobStatusBadge";
import { ServiceProfileStatus } from "./ServiceProfileStatus";
import { NullBlankValue } from "./NullBlankField";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { SortIndicator } from "../shared/SortIndicator";
import { ActorPureNameDisplay } from "../sales/ActorNameDisplay";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { NewContactLogModal } from "./NewContactLogModal";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";


export function ServiceProfilePage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessTypeId, setBusinessTypeId] = useState(null);
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [address, setAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [nrc, setNrc] = useState("");
  const [designation, setDesignation] = useState("");

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingGenerators, setIsLoadingGenerators] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _customerId = state['customerId'];
    let _profileId = state['serviceProfileId'];
    if (!state || !_customerId || !_profileId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      fetchUserPermissions();

      setCustomerId(_customerId);
      setServiceProfileId(_profileId);
      fetchCustomerServiceProfile(_customerId, _profileId);
    }
  }, []);

  const fetchCustomerServiceProfile = (customerId, serviceProfileId) => {
    setIsLoadingProfile(true);
    let params = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    technicalServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        setServiceProfile(_serviceProfile);
        prepareCustomerDetails(_serviceProfile);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingProfile(false);
      });

    setIsLoadingGenerators(true);
    technicalServices.fetchServiceProfileGenerators(serviceProfileId)
      .then((response) => {
        let _responseData = response['data'];
        setServiceLocations(_responseData);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingGenerators(false);
      });

    fetchProfileContactLogs(serviceProfileId);
  }

  const handleErrorResponse = (error) => {
    let errorResponse = error['response'];
    if (errorResponse) {
      if (errorResponse.status === 404 || errorResponse.status === 400) {
        navigate(routes.serviceProfiles.url);
      }
    }
  }

  const prepareCustomerDetails = (serviceProfile) => {
    if (!serviceProfile) {
      return;
    }
    setCustomerName(serviceProfile['customerName']);
    setCompanyName(serviceProfile['companyName']);
    setBusinessTypeId(serviceProfile['businessTypeId']);
    setBusinessTypeName(serviceProfile['businessTypeName']);
    setAddress(serviceProfile['address']);
    setContactName(serviceProfile['contactFullName']);
    setContactNo(serviceProfile['contactNo']);
    setNrc(serviceProfile['nrc']);
    setDesignation(serviceProfile['designation']);
  }
  //#endregion

  //#region States; User permissions
  const [allowCustomerEdit, setAllowCustomerEdit] = useState(false);
  const [allowNewCommission, setAllowNewCommission] = useState(false);
  const [allowCareContactLog, setAllowCareContactLog] = useState(false);
  const [allowManageLocations, setAllowManageLocations] = useState(false);
  const [allowManagePortalUsers, setAllowManagePortalUsers] = useState(false);

  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const _servicePermissions = response['data']['service'];
        setAllowCareContactLog(_servicePermissions['allowCareContactLog']);
      });
    authService2.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const _customerPermissions = response['data']['customer'];
        setAllowCustomerEdit(_customerPermissions['allowProfileEdit']);

        const _servicePermissions = response['data']['service'];
        setAllowManageLocations(_servicePermissions['allowManageLocations']);
        setAllowManagePortalUsers(_servicePermissions['allowManagePortalUsers']);
        setAllowNewCommission(_servicePermissions['allowNewCommission']);
      });
  }
  //#region

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchUserPermissions();
    fetchCustomerServiceProfile(customerId, serviceProfileId);
  }

  const onManageLocationsClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.manageProfileLocations.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onNewCommissionClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.newGeneratorCommission.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onManageUsersClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.managePortalUsers.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorRowClicked = (ev, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['id'],
          'profileId': serviceProfileId,
          'customerId': customerId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Customer details section; States & control handlers for updating Customer Profile
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false);
  const [originalCustomerDetails, setOriginalCustomerDetails] = useState({});
  const [hasCustomerErrors, setHasCustomerErrors] = useState(false);
  const [customerErrors, setCustomerErrors] = useState({});

  const [isCheckingCustomerUsage, setIsCheckingCustomerUsage] = useState(false);
  const [customerUsage, setCustomerUsage] = useState({});

  const onEditCustomerClicked = (ev) => {
    setIsEditingCustomer(true);
    setOriginalCustomerDetails({
      'customerName': customerName,
      'companyName': companyName,
      'businessTypeId': businessTypeId,
      'businessTypeName': businessTypeName,
      'address': address,
      'contactName': contactName,
      'contactNo': contactNo,
      'nrc': nrc,
      'designation': designation,
    });
  }

  const isCustomerFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      customerName: [],
      contactFullName: [],
      contactNo: [],
    };
    if (!customerName || customerName.trim() === '') {
      _hasErrors = true;
      _formErrors.customerName = ["Customer name is required."];
    }
    if (!contactName || contactName.trim() === '') {
      _hasErrors = true;
      _formErrors.contactFullName = ["Contact name is required."];
    }
    if (!contactNo || contactNo.trim() === '') {
      _hasErrors = true;
      _formErrors.contactNo = ["Contact number is required."];
    }

    setCustomerErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareCustomerPayload = () => {
    return {
      'customer_name': customerName.trim(),
      'company_name': companyName ? companyName.trim() : null,
      'business_type_id': businessTypeId,
      'address': address ? address.trim() : null,
      'contact_fullname': contactName.trim(),
      'contact_no': contactNo.trim(),
      'contact_nrc': nrc ? nrc.trim() : null,
      'designation': designation ? designation.trim() : null,
      'employee_id': auth.getUserId()['eid'],
    }
  }

  const onSaveCustomerClicked = (ev) => {
    if (!isCustomerFieldsValid()) {
      setHasCustomerErrors(true);
      setAlertType(alertTypes.error);
      setAlertMessage("Fix the indicated errors before submitting again.");
      setIsAlertVisible(true);
      return;
    }
    else {
      setHasCustomerErrors(false);
      setIsAlertVisible(false);
    }

    const payload = {
      'customer_id': customerId,
      'profile_id': serviceProfileId,
      ...prepareCustomerPayload(),
    }
    setIsSubmittingCustomer(true);
    customerServices2.updateCustomerDetails(payload)
      .then((response) => {
        setHasCustomerErrors(false);
        setOriginalCustomerDetails({});
        setIsEditingCustomer(false);
        setIsAlertVisible(false);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        let errorData = errorResponse['data'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            setAlertType(alertTypes.error);
            setAlertMessage(errorData['error']);
            setIsAlertVisible(true);
          }
          if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unknown server error occurred. Please contact the administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        setIsSubmittingCustomer(false);
      });
  }

  const onCancelCustomerClicked = (ev) => {
    setIsEditingCustomer(false);
    setIsSubmittingCustomer(false);
    setHasCustomerErrors(false);
    setIsAlertVisible(false);

    setIsCheckingCustomerUsage(false);
    setCustomerUsage({});

    if (!originalCustomerDetails) {
      return;
    }
    setCustomerName(originalCustomerDetails['customerName']);
    setCompanyName(originalCustomerDetails['companyName']);
    setBusinessTypeId(originalCustomerDetails['businessTypeId']);
    setBusinessTypeName(originalCustomerDetails['businessTypeName']);
    setAddress(originalCustomerDetails['address']);
    setContactName(originalCustomerDetails['contactName']);
    setContactNo(originalCustomerDetails['contactNo']);
    setNrc(originalCustomerDetails['nrc']);
    setDesignation(originalCustomerDetails['designation']);
  }

  const getCustomerUsageInfoSection = () => {
    if (!isEditingCustomer) {
      return <></>
    }

    return (
      <div className={"form-message"}>
        <WarningMessage>
          <b>Be careful when updating the customer details.</b> Any changes here will be reflected in the associated Customer Profile and any other places referencing this Customer Profile.
        </WarningMessage>
      </div>
    )
  }
  //#endregion

  //#region Profile Care Contact Logs
  const [isLoadingContactLogs, setIsLoadingContactLogs] = useState(false);
  const [profileContactLogs, setProfileContactLogs] = useState([]);

  const fetchProfileContactLogs = (serviceProfileId) => {
    setProfileContactLogs([]);
    setIsLoadingContactLogs(true);

    customerServices2.fetchServiceProfileContactLogs(serviceProfileId)
      .then((response) => {
        let _responseData = response['data'];
        setProfileContactLogs(_responseData['data']);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingContactLogs(false);
      });
  }

  const onProfileContactLogRowClicked = (ev, contactLog) => {
    // NOTE(yemon): 'serviceHistoryId' field is representing the 'id' value of care contact log
    // for the Service Profile entries. This is just that way to maintain consistency
    // with the other listing queries (which utilizes unions) with the service history contact logs
    // as well. Don't get confused.
    const careContactId = contactLog['serviceHistoryId'];
    navigate(routes.contactLogProfileEntry.url, {
      state: {
        'serviceHistoryId': careContactId,
        'serviceProfileId': serviceProfileId,
        'customerId': customerId,
      }
    });
  }
  //#endregion

  //#region Utilities
  const isLoading = () => {
    return isLoadingProfile || isLoadingGenerators || isLoadingContactLogs;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem text={customerName} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{customerName}</h1>
          </div>

          <ServiceEntryCustomer customerId={customerId} 
                                customerName={customerName} onCustomerNameChanged={(ev) => setCustomerName(ev.target.value)}
                                companyName={companyName} onCompanyNameChanged={(ev) => setCompanyName(ev.target.value)}
                                businessTypeId={businessTypeId} onBusinessTypeIdChanged={(ev) => setBusinessTypeId(parseInt(ev.target.value))}
                                businessTypeName={businessTypeName} 
                                address={address} onAddressChanged={(ev) => setAddress(ev.target.value)}
                                contactName={contactName} onContactNameChanged={(ev) => setContactName(ev.target.value)}
                                contactNo={contactNo} onContactNoChanged={(ev) => setContactNo(ev.target.value)}
                                nrc={nrc} onNrcChanged={(ev) => setNrc(ev.target.value)}
                                designation={designation} onDesignationChanged={(ev) => setDesignation(ev.target.value)}
                                isLoading={false} isEditable={isEditingCustomer} isSubmitting={isSubmittingCustomer}
                                hasErrors={hasCustomerErrors} formErrors={customerErrors}
                                messageSection={allowCustomerEdit ? getCustomerUsageInfoSection() : null}>
            {allowCustomerEdit && 
              <div className={"form-section-controls"}>
                {!isEditingCustomer &&
                  <button className={"btn btn-secondary"} onClick={onEditCustomerClicked}>
                    <i className={"fa-solid fa-pencil"}></i>Edit
                  </button>
                }
                {isEditingCustomer &&
                  <>
                    <button className={"btn btn-primary"} disabled={isSubmittingCustomer}
                            onClick={onSaveCustomerClicked}>
                      {isSubmittingCustomer && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                      {!isSubmittingCustomer && <i className={"fa-solid fa-check"}></i>}
                      Save
                    </button>
                    <button className={"btn btn-secondary"} disabled={isSubmittingCustomer}
                            onClick={onCancelCustomerClicked}>
                      <i className={"fa-solid fa-xmark"}></i>Cancel
                    </button>
                  </>
                }
              </div>
            }
          </ServiceEntryCustomer>

          <div className={"form-section"}>
            <h2>Profile Details</h2>

            <div className={"entry-form"}>
              <div className={"form-label"}>
                <label>
                  Profile Status:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceProfile &&
                    <ServiceProfileStatus profileStatus={serviceProfile['status']}
                                          profileActiveDatetime={serviceProfile['activeDatetime']}
                                          profileInactiveDatetime={serviceProfile['inactiveDatetime']}
                    />
                  }
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section-controls"}>
            <h2>Manage Service Profile</h2>

            <div className={"form-button-controls"}>
              <div className={"left-side wide-col"}>
                <button className={"btn btn-secondary right-margin"} disabled={isLoading()}
                        onClick={onRefreshClicked}>
                  {isLoading() && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading() && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>
              </div>

              {(allowManageLocations || allowManagePortalUsers) &&
                <div className={"right-side narrow-col right-aligned"}>
                  {allowManageLocations &&
                    <button className={"btn btn-secondary"} disabled={isLoading()}
                            onClick={onManageLocationsClicked}>
                      <i className={"fa-solid fa-location-dot"}></i>
                      Manage Locations
                    </button>
                  }

                  {allowManagePortalUsers &&
                    <button className={"btn btn-secondary"} disabled={isLoading()}
                            onClick={onManageUsersClicked}>
                      <i className={"fa-solid fa-user-gear"}></i>
                      Manage Portal Users
                    </button>
                  }
                </div>
              }
            </div>
          </div>

          <TabsPanelProvider initialHeaderElementId={"service-generators-tab"}
                             initialContentPaneElementId={"service-generators-content"}>
            <TabHeader>
              <TabHeaderItem itemElementId={"service-generators-tab"} contentPaneElementId={"service-generators-content"}
                             itemLabel={"Commissioned Generators"} />
              <TabHeaderItem itemElementId={"profile-contact-logs-tab"} contentPaneElementId={"profile-contact-logs-content"}
                             itemLabel={"Care Contact Logs"} />
            </TabHeader>
            <TabContent>
              <ServiceGeneratorsTabPane serviceLocations={serviceLocations} isLoading={isLoading} allowNewCommission={allowNewCommission}
                                        onNewCommissionClicked={onNewCommissionClicked}
                                        onGeneratorRowClicked={onGeneratorRowClicked} />
              <ProfileContactLogsTabPane profileContactLogs={profileContactLogs} isLoading={isLoading} allowCareContactLog={allowCareContactLog}
                                         serviceProfileId={serviceProfileId} customerId={customerId}
                                         onProfileContactRowClicked={onProfileContactLogRowClicked} />
            </TabContent>
          </TabsPanelProvider>

          <PageAlert visible={isAlertVisible} type={alertType}
                    onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const ServiceGeneratorsTabPane = ({
                                    serviceLocations, isLoading, allowNewCommission,
                                    onNewCommissionClicked, onGeneratorRowClicked,
                                  }) => {
  return (
    <TabContentPane contentPaneElementId={"service-generators-content"}>
      {allowNewCommission &&
        <div className={"form-section-controls"} style={{ marginTop: "0" }}>
          <button className={"btn btn-primary"} disabled={isLoading()}
                  onClick={onNewCommissionClicked}>
            <i className="fa-solid fa-user-gear"></i>
            New Generator Commission
          </button>
        </div>
      }

      <div className={"form-section"}>
        <div className={"data-table"}>
          <table>
            <thead>
            <tr>
              <th scope={"col"} className={"index-col-head"}>#</th>
              <th scope={"col"}>Generator Serial</th>
              <th scope={"col"}>Generator Model</th>
              <th scope={"col"}>T&C/Installation Date</th>
              <th scope={"col"}>Commission Type</th>
              <th scope={"col"}>Warranty Status</th>
            </tr>
            </thead>
            <tbody>
            {isLoading() && <TableLoadingIndicator colspan={6} />}

            {serviceLocations && serviceLocations.length > 0 && !isLoading() &&
              serviceLocations.map((serviceLocation, index) =>
                <ServiceLocationRow serviceLocation={serviceLocation} rowIndex={index} index={index}
                                    key={serviceLocation['id']} onRowClicked={onGeneratorRowClicked}
                />
              )
            }

            {!serviceLocations || (serviceLocations.length === 0 && !isLoading() &&
              <TableEmptyRow colSpan={6} />
            )}
            </tbody>
          </table>
        </div>

      </div>
    </TabContentPane>
  )
}


const ServiceLocationRow = ({
                              serviceLocation, rowIndex, index,
                              onRowClicked,
                            }) => {
  return (
    <>
      <tr className={"highlight"}>
        <td className={"index-col"}>{index + 1}</td>
        <td>
          <span title={"Location"}>
            {serviceLocation['name']}
          </span>
        </td>
        <td colSpan={4}>
          <span title={"City / Township"}>
            {getRegionDisplayByName(serviceLocation['cityName'], serviceLocation['townshipName'])}
          </span>
        </td>
      </tr>
      {serviceLocation && serviceLocation['generators'] && serviceLocation['generators'].length > 0 &&
        serviceLocation['generators'].map((generator, index) =>
          <ServiceGeneratorRow generator={generator} rowIndex={rowIndex} index={index}
                               key={generator['id']} onRowClicked={onRowClicked} />
        )
      }
    </>
  )
}

const ServiceGeneratorRow = ({
                               generator, rowIndex, index,
                               onRowClicked,
                             }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {`${rowIndex + 1}.${index + 1}`}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, generator)}>
          {generator['generatorSerial']}
        </a>
      </td>
      <td>
        {generator['gensetModel']}
      </td>
      <td>
        <Moment date={generator['commissionDate']} format={formatters.datetimeShort} />
      </td>
      <td>
        {refs.inventory.serviceGeneratorCommissionType[generator['commissionType']]}
      </td>
      <td>
        {refs.inventory.serviceGeneratorWarrantyStatus[isUnderWarrantyFromStatus(generator['warrantyStatus'])]}
      </td>
    </tr>
  )
}


const ProfileContactLogsTabPane = ({
                                     profileContactLogs, isLoading, allowCareContactLog,
                                     serviceProfileId, customerId,
                                     onProfileContactRowClicked,
                                   }) => {
  const [isNewContactModalOpened, setIsNewContactModalOpened] = useState(false);

  const onNewContactLogClicked = (ev) => {
    newContactModal.onOpenButtonClicked();
  }

  const newContactModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(false);
      }, 200);
    },
  }

  return (
    <TabContentPane contentPaneElementId={"profile-contact-logs-content"}>
      <NewContactLogModal isOpen={isNewContactModalOpened}
                          onRequestClose={newContactModal.onCloseButtonClicked}
                          serviceProfileId={serviceProfileId} customerId={customerId} />

      {allowCareContactLog &&
        <div className={"form-section-controls"} style={{ marginTop: "0" }}>
          <button className={"btn btn-primary"} disabled={isLoading()}
                  onClick={onNewContactLogClicked}>
            <i className="fa-solid fa-plus"></i>
            New Contact Log...
          </button>
        </div>
      }

      <div className={"form-section"}>
        <div className={"data-table"}>
          <table>
            <thead>
            <tr>
              <th scope="col" className={"index-col-head"}>#</th>
              <th scope="col">
                Logged Date
                <SortIndicator isAscending={false} />
              </th>
              <th scope="col">Logged By / Branch</th>
              <th scope="col">Call Type</th>
              <th scope="col">Contact Type</th>
              <th scope="col">Satisfaction</th>
            </tr>
            </thead>
            <tbody>
            {isLoading() && <TableLoadingIndicator colspan={6} />}

            {profileContactLogs && profileContactLogs.length > 0 && !isLoading() &&
              profileContactLogs.map((contactLog, index) =>
                <ProfileContactLogRow contactLog={contactLog} index={index}
                                      key={contactLog['serviceHistoryId']}
                                      onRowClicked={onProfileContactRowClicked} />
              )
            }

            {!profileContactLogs || (profileContactLogs.length === 0 && !isLoading() &&
              <TableEmptyRow colSpan={6} />
            )}
            </tbody>
          </table>
        </div>

      </div>
    </TabContentPane>
  )
}


const callTypes = refs.customer.callType;
const contactTypes = refs.customer.contactType;
const contactSatisfactions = refs.customer.satisfaction;

const ProfileContactLogRow = ({
                                contactLog, index,
                                onRowClicked
                              }) => {
  const getContactSatisfaction = (satisfaction) => {
    if (satisfaction === contactSatisfactions.na) {
      return (
        <span style={{ color: 'gray', fontStyle: 'italic' }}>
          (NA)
        </span>
      )
    } else {
      return <>{contactSatisfactions[satisfaction]}</>
    }
  }

  return (
    <tr>
      <td className={"index-col"}>
        {index + 1}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"View the contact log entry."}
           onClick={(ev) => onRowClicked(ev, contactLog)}>
          <Moment date={contactLog['recordedDatetime']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        <ActorPureNameDisplay employeeId={contactLog['recordedById']}
                              employeeName={contactLog['recordedByName']} />&nbsp;/&nbsp;
        <span title={"Branch"}>
          {contactLog['recordedByBranchName'] ? contactLog['recordedByBranchName'] : '-'}
        </span>
      </td>
      <td>
        {callTypes[contactLog['callType']]}
      </td>
      <td>
        {contactTypes[contactLog['contactType']]}
      </td>
      <td>
        {getContactSatisfaction(contactLog['contactSatisfaction'])}
      </td>
    </tr>
  )
}
