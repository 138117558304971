import axios from "axios";

let host = "http://localhost:8001/api";
// let host = "http://localhost:62241/api";
if (process.env.REACT_APP_ENV === "sit") {
  host = "/api";
} else if (process.env.REACT_APP_ENV === "staging") {
  host = "https://stage-ptimis2-38cdfed39666.herokuapp.com/api";
} else if (process.env.REACT_APP_ENV === "production") {
  host = "https://prod-ptimis2-02931404788a.herokuapp.com/api";
}

let portalHost = host + '/p';

const routes =  {
  "auth": {
    // ...
    "userInfo": `${host}/system/auth/user-info/`,
    "userPermissions": `${host}/system/auth/permissions/`,
  },
  "ref": {
    "businessTypes": `${host}/ref/business-types/`,
    "searchGenerators": `${host}/ref/generators/search/`,
    "searchGeneratorsOrATS": `${host}/ref/generators-ats/search/`,
    "regionsSearch": `${host}/ref/regions/search/`,
    "regionsList": `${host}/ref/regions/`,
    // "salesPersonsList": `${host}/ref/sales-persons/`,
    "employeesListByBranch": `${host}/ref/employees-by-branch/`,
  },
  "profiles": {
  },
  "sales": {
    // TODO(yemon): migrate the original sales routes
    inquiry: {
      listExport: `${host}/sales/inquiry-list/export/`,
    },

    salesTarget: {
      salesTeamWithTargets: `${host}/sales/sales-team-with-targets/`,
      submitSalesTeam: `${host}/sales/sales-team/`,
    },
  },
  "inventory": {
    // ...
  },
  "technical": {
    "stockInspectionsSearch": `${host}/stock/stock-inspections-search/`,
    "stockInspectionsSummary": `${host}/stock/stock-inspections-summary/`,

    "serviceProfileCustomerSearch": `${host}/service/service-profile/customer-search/`,
    "serviceProfileDetails": `${host}/service/service-profile/`,
    "serviceProfileGenerators": `${host}/service/service-profile-generators/`,
    "serviceProfileGenerator": `${host}/service/service-profile-generator/`,
    "serviceGeneratorJobHistory": `${host}/service/service-profile-generator/job-history/`,

    "serviceProfileTargetableLocations": `${host}/service/service-profile-targetable-locations/`,
    "serviceGeneratorTransferLocation": `${host}/service/service-profile-generator/location-transfer/`,
    "serviceProfileTargetableCustomers": `${host}/service/service-profile-targetable-customers/`,
    "serviceGeneratorTransferCustomer": `${host}/service/service-profile-generator/customer-transfer/`,

    "jobHistorySearch": `${host}/service/job-history-search/`,
    "jobHistorySummary": `${host}/service/job-history-summary/`,
  },
  "customer": {
    "profileStatus": `${host}/customer/profile-status/`,
    "list": `${host}/customer/profile-list/`,
    "customerDetailsUpdate": `${host}/customer/profile-details/`,
    "careHistoryContactSubmit": `${host}/customer/care/history-contact/`,
    "careContactLogsSearch": `${host}/customer/care/contact-logs-search/`,
    "careContactLogsSummary": `${host}/customer/care/contact-logs-summary/`,
    "profileContactLogs": `${host}/customer/service-profile-contact-logs/`,
  },
  "portal": {
    //...
    portalUserPermissions: `${portalHost}/portal-user-permissions/`,
  },
  "report": {
    reportsList: `${host}/report/reports-list/`,
    employeeBranchGroups: `${host}/report/references/employee-branch-groups/`,

    // Sales reports
    inquiryRecords: {
      fetch: `${host}/report/inquiry-records/`,
      submit: `${host}/report/inquiry-records/submit/`,
      generateSummary: `${host}/report/inquiry-records/generate-summary/`,
    },
    salesPersonSummaries: {
      fetch: `${host}/report/sales-person-summaries/`,
      submit: `${host}/report/sales-person-summaries/submit/`,
      generate: `${host}/report/sales-person-summaries/generate/`,
    },

    // Technical/Service reports
    overWarrantyGenerators: { 
      fetch: `${host}/report/over-warranty-generators/`,
      submit: `${host}/report/over-warranty-generators/submit/`,
      generate: `${host}/report/over-warranty-generators/generate/`,
    },
    warrantyStatuses: {
      fetch: `${host}/report/warranty-statuses/`,
      submit: `${host}/report/warranty-statuses/submit/`,
      generate: `${host}/report/warranty-statuses/generate/`,
    },
    workDoneSummary: {
      fetch: `${host}/report/work-done-summary/`,
      submit: `${host}/report/work-done-summary/submit/`,
      generate: `${host}/report/work-done-summary/generate/`,
    },

  },
  "setup": {
    "statesDivisionsList": `${host}/setup/states-divisions/`,
    "businessTypesList": `${host}/setup/business-types/`,
    "businessType": `${host}/setup/business-type/`,
    "businessTypeSubmit": `${host}/setup/business-type/`,
    "businessTypeUsages": `${host}/setup/business-type-usages/`,
  },
  "system": {
    "userAccountsList": `${host}/system/user-accounts/`,
    "userRolesList": `${host}/system/user-roles/`,
    "groupsList": `${host}/system/groups/`,
    
    "reportPermissions": `${host}/system/report/permissions/`,
  },
}

const options = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
};

const getAuthenticatedOptions = () => {
  let token = localStorage.getItem('token');
  if (token == null) return options;
  let authOptions = options;
  authOptions.headers['Authorization'] = `Bearer ${token}`;
  return authOptions;
}

const getAuthenticatedBlobOptions = () => {
  let options = {
    'responseType': 'blob',
  };
  let token = localStorage.getItem('token');
  if (token == null) return options;
  options['headers'] = {
    'Authorization': `Bearer ${token}`,
  };
  return options;
}


export const authService = {
  // ...

  fetchUserInfo: function(username, accessToken) {
    let authOptions = options;
    authOptions.headers['Authorization'] = `Bearer ${accessToken}`;
    return axios.get(routes.auth.userInfo + username, authOptions);
  },

  fetchUserPermissions: function(userIdentifier) {
    return axios.post(routes.auth.userPermissions, userIdentifier, getAuthenticatedOptions());
  },
}


export const refServices = {
  fetchBusinessTypes: function() {
    return axios.get(routes.ref.businessTypes, getAuthenticatedOptions());
  },

  searchGenerators: function(term) {
    return axios.get(routes.ref.searchGenerators + term, getAuthenticatedOptions());
  },

  searchGeneratorsOrATS: function(term) {
    return axios.get(routes.ref.searchGeneratorsOrATS + term, getAuthenticatedOptions());
  },

  searchRegions: function(term) {
    return axios.get(routes.ref.regionsSearch + "?term=" + term, getAuthenticatedOptions());
  },

  fetchAllRegions: function() {
    return axios.get(routes.ref.regionsList, getAuthenticatedOptions());
  },

  // NOTE(yemon): not used!
  fetchSalesPersonsList: function(payload) {
    //return axios.post(routes.ref.salesPersonsList, payload, getAuthenticatedOptions());
  },

  fetchEmployeesListByBranch: function(payload) {
    return axios.post(routes.ref.employeesListByBranch, payload, getAuthenticatedOptions());
  },
}


export const profilesServices = {}


export const salesServices = {
  inquiry: {
    // ...

    postListExport: function(searchOptionsPayload) {
      return axios.post(routes.sales.inquiry.listExport, searchOptionsPayload, getAuthenticatedBlobOptions());
    },

    // ...
  },

  salesTarget: {
    fetchSalesTeamWithTargets: function() {
      return axios.get(routes.sales.salesTarget.salesTeamWithTargets, getAuthenticatedOptions());
    },

    submitSalesTeam: function(payload) {
      return axios.post(routes.sales.salesTarget.submitSalesTeam, payload, getAuthenticatedOptions());
    },
  },
}


// NOTE(yemon): The original 'inventory' module will be renamed to the more appropriate
// 'technical' as part of the API2 migration. Some routes will still be 'inventory' for
// backward compatibility purposes, but they will be revisited and slowly refactored out
// gradually later down the line.
export const inventoryServices = {
}

export const technicalServices = {
  searchStockInspections: function(payload) {
    return axios.post(routes.technical.stockInspectionsSearch, payload, getAuthenticatedOptions());
  },

  summarizeStockInspections: function(payload) {
    return axios.post(routes.technical.stockInspectionsSummary, payload, getAuthenticatedOptions());
  },

  // ...

  fetchCustomerServiceProfile: function(params) {
    return axios.get(routes.technical.serviceProfileDetails + params['customer_id'] + '/' + params['service_profile_id'],
        getAuthenticatedOptions()
    );
  },

  fetchServiceProfileGenerators: function(serviceProfileId) {
    return axios.get(routes.technical.serviceProfileGenerators + serviceProfileId, getAuthenticatedOptions());
  },

  fetchServiceProfileGenerator: function(params) {
    return axios.get(routes.technical.serviceProfileGenerator + params['service_generator_id'], getAuthenticatedOptions());
  },

  fetchServiceGeneratorJobHistory: function(payload) {
    return axios.post(routes.technical.serviceGeneratorJobHistory, payload, getAuthenticatedOptions());
  },


  // ...

  fetchServiceProfileTargetableLocations: function(serviceProfileId, sourceLocationId) {
    return axios.get(`${routes.technical.serviceProfileTargetableLocations}${serviceProfileId}/${sourceLocationId}`, getAuthenticatedOptions());
  },

  trasnferServiceGeneratorLocation: function(payload) {
    return axios.post(routes.technical.serviceGeneratorTransferLocation, payload, getAuthenticatedOptions());
  },

  fetchServiceProfileTargetableCustomers: function(payload) {
    return axios.post(routes.technical.serviceProfileTargetableCustomers, payload, getAuthenticatedOptions());
  },

  transferServiceGeneratorCustomer: function(payload) {
    return axios.post(routes.technical.serviceGeneratorTransferCustomer, payload, getAuthenticatedOptions());
  },

  // ...

  searchJobHistories: function(payload) {
    return axios.post(routes.technical.jobHistorySearch, payload, getAuthenticatedOptions());
  },

  summarizeJobHistories: function(payload) {
    return axios.post(routes.technical.jobHistorySummary, payload, getAuthenticatedOptions());
  },
}


export const customerServices = {
  setProfileStatus: function(payload) {
    // return axios.post(routes.customer.profileStatus, payload, getAuthenticatedOptions());
  },

  fetchCustomerProfiles: function(tableOptions) {
    return axios.post(routes.customer.list, tableOptions, getAuthenticatedOptions());
  },

  updateCustomerDetails: function(payload) {
    return axios.put(routes.customer.customerDetailsUpdate, payload, getAuthenticatedOptions());
  },

  submitCareHistoryContact: function(payload) {
    // return axios.post(routes.customer.careHistoryContactSubmit, payload, getAuthenticatedOptions());
  },

  searchCareContactLogs: function(payload) {
    return axios.post(routes.customer.careContactLogsSearch, payload, getAuthenticatedOptions());
  },

  summarizeCareContactLogs: function(payload) {
    return axios.post(routes.customer.careContactLogsSummary, payload, getAuthenticatedOptions());
  },

  fetchServiceProfileContactLogs: function(serviceProfileId) {
    return axios.get(routes.customer.profileContactLogs + serviceProfileId, getAuthenticatedOptions());
  },
}


export const portalServices = {
  //...

  submitPortalUserPermissions: function(payload) {
    return axios.post(routes.portal.portalUserPermissions, payload, getAuthenticatedOptions());
  },
}

export const reportServices = {
  fetchReportsList: function(payload) {
    return axios.post(routes.report.reportsList, payload, getAuthenticatedOptions());
  },

  fetchEmployeeBranchGroups: function() {
    return axios.get(routes.report.employeeBranchGroups, getAuthenticatedOptions());
  },

  // Sales reports
  inquiryRecords: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.inquiryRecords.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.inquiryRecords.submit, payload, getAuthenticatedOptions());
    },

    generateSummary: function(payload) {
      return axios.post(routes.report.inquiryRecords.generateSummary, payload, getAuthenticatedOptions());
    },
  },
  salesPersonSummaries: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.salesPersonSummaries.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.salesPersonSummaries.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.salesPersonSummaries.generate, payload, getAuthenticatedOptions());
    },
  },

  // Technical/Service reports
  overWarrantyGenerators: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.overWarrantyGenerators.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.overWarrantyGenerators.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.overWarrantyGenerators.generate, payload, getAuthenticatedOptions());
    },
  },
  warrantyStatuses: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.warrantyStatuses.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.warrantyStatuses.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.warrantyStatuses.generate, payload, getAuthenticatedOptions());
    },
  },
  workDoneSummary: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.workDoneSummary.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.workDoneSummary.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.workDoneSummary.generate, payload, getAuthenticatedOptions());
    },
  },

}

export const setupServices = {
  fetchStatesDivisions: function() {
    return axios.get(routes.setup.statesDivisionsList, getAuthenticatedOptions());
  },

  fetchBusinessTypes: function() {
    return axios.get(routes.setup.businessTypesList, getAuthenticatedOptions());
  },

  fetchBusinessType: function(businessTypeId) {
    return axios.get(`${routes.setup.businessType}${businessTypeId}`, getAuthenticatedOptions());
  },

  submitBusinessType: function(payload) {
    return axios.post(routes.setup.businessTypeSubmit, payload, getAuthenticatedOptions());
  },

  checkBusinessTypeUsages: function(businessTypeId) {
    return axios.get(`${routes.setup.businessTypeUsages}${businessTypeId}`, getAuthenticatedOptions());
  },
}

export const systemServices = {
  fetchUserAccounts: function() {
    return axios.get(routes.system.userAccountsList, getAuthenticatedOptions());
  },

  fetchUserRoles: function() {
    return axios.get(routes.system.userRolesList, getAuthenticatedOptions());
  },

  fetchGroups: function() {
    return axios.get(routes.system.groupsList, getAuthenticatedOptions());
  },

  postReportPermissions: function(payload) {
    return axios.post(routes.system.reportPermissions, payload, getAuthenticatedOptions());
  },
}
