import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { navigableRoutes as routes } from "../../repos/constants";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { technicalServices } from "../../repos/apiServices2";
import { getRegionDisplayByName } from "../../repos/utilities";
import { getWarrantySpecifications } from "../../repos/generatorUtilities";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";

import { ModalContainer } from "../shared/ModalContainer";
import { ReadonlyField } from "../shared/ReadonlyField";
import { InfoMessage } from "../sales/FormMessages";
import { NullBlankValue } from "./NullBlankField";
import { ConfirmPrompt } from "../shared/PromptMessages";
import { ConfirmYesButton } from "../shared/PromptMessages";
import { ConfirmNoButton } from "../shared/PromptMessages";

import "../shared/Modals.css";

const commissionTypes = refs.inventory.serviceGeneratorCommissionType;
const warrantyStatuses = refs.inventory.serviceGeneratorWarrantyStatus;

export const LocationsTransferModal = ({
                                        isOpen, onRequestClose,
                                        serviceProfile, commission, profileId,
                                        selectedServiceProfile, selectedGeneratorId,
                                        gensetModel, generatorSerial,
                                        setIsAlertVisible, showErrorAlertMessage,
                                      }) => {
  const [customerName, setCustomerName] = useState('');

  const [targetableLocations, setTargetableLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [selectedLocationContactName, setSelectedLocationContactName] = useState("");
  const [selectedLocationContactNo, setSelectedLocationContactNo] = useState("");
  const [selectedLocationAddress, setSelectedLocationAddress] = useState("");
  const [selectedLocationCityName, setSelectedLocationCityName] = useState("");
  const [selectedLocationTownshipName, setSelectedLocationTownshipName] = useState(null);

  const [isConfirming, setIsConfirming] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (!serviceProfile || serviceProfile === null) {
      return;
    }
    setTargetableLocations(serviceProfile['locations']);
    setCustomerName(serviceProfile['customerName']);
  }, [serviceProfile]);

  useEffect(() => {
    if (targetableLocations.length > 0) {
      const _firstLocation = targetableLocations[0];
      setSelectedLocationId(_firstLocation['id']);
      setSelectedLocationName(_firstLocation['name']);
      setSelectedLocationContactName(_firstLocation['contactFullName']);
      setSelectedLocationContactNo(_firstLocation['contactNo']);
      setSelectedLocationAddress(_firstLocation['address']);
      setSelectedLocationCityName(_firstLocation['cityName']);
      setSelectedLocationTownshipName(_firstLocation['townshipName']);
    } 
    else {
      setSelectedLocationId(null);
      setSelectedLocationName("(NA)");
      setSelectedLocationContactName("(NA)");
      setSelectedLocationContactNo("(NA)");
      setSelectedLocationAddress("(NA)");
      setSelectedLocationCityName("(NA)");
      setSelectedLocationTownshipName(null);
    }
  }, [targetableLocations]);

  const onCloseButtonClicked = (ev) => {
    if (isSubmitting) {
      return;
    }
    setIsConfirming(false);
    onRequestClose();
  }

  const onViewProfileClicked = (ev) => {
    if (!selectedServiceProfile) {
      return;
    }
    navigate(routes.serviceProfile.url, {
      state: {
        'customerId': selectedServiceProfile['customerId'],
        'serviceProfileId': selectedServiceProfile['profileId'],
      },
    });
  }

  const onTargetLocationSelected = (ev) => {
    if (targetableLocations.length === 0) {
      setSelectedLocationId(null);
      setSelectedLocationName("(NA)");
      setSelectedLocationContactName("(NA)");
      setSelectedLocationContactNo("(NA)");
      setSelectedLocationAddress("(NA)");
      setSelectedLocationCityName("(NA)");
      setSelectedLocationTownshipName(null);
    }
    else {
      const _selectedLocation = targetableLocations.find((location) => location['id'] === ev.target.value);
      if (_selectedLocation) {
        setSelectedLocationId(_selectedLocation['id']);
        setSelectedLocationName(_selectedLocation['name']);
        setSelectedLocationContactName(_selectedLocation['contactFullName']);
        setSelectedLocationContactNo(_selectedLocation['contactNo']);
        setSelectedLocationAddress(_selectedLocation['address']);
        setSelectedLocationCityName(_selectedLocation['cityName']);
        setSelectedLocationTownshipName(_selectedLocation['townshipName']);
      }
      else {
        setSelectedLocationId(null);
        setSelectedLocationName("(NA)");
        setSelectedLocationContactName("(NA)");
        setSelectedLocationContactNo("(NA)");
        setSelectedLocationAddress("(NA)");
        setSelectedLocationCityName("(NA)");
        setSelectedLocationTownshipName(null);
      }
    }
  }

  const onYesSubmitClicked = (ev) => {
    setIsSubmitting(true);
    const payload = {
      'serviceGeneratorId': selectedGeneratorId,
      'originalProfileId': profileId,
      'targetProfileId': selectedServiceProfile['profileId'],
      'targetLocationId': selectedLocationId,
      'employeeId': auth.getUserId()['eid'],
    };
    // TODO(yemon): showing page alerts from the modal is a problem, it's just janky!!
    technicalServices.transferServiceGeneratorCustomer(payload)
      .then((response) => {
        setIsAlertVisible(false);
        navigate(routes.serviceProfile.url, {
          state: {
            'customerId': selectedServiceProfile['customerId'],
            'serviceProfileId': selectedServiceProfile['profileId'],
          },
        });
      })
      .catch((error) => {
        const errorResponse = error['response'];
        const errorData = errorResponse['data'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            showErrorAlertMessage(errorData['error']);
          }
          if (errorResponse.status === 500) {
            showErrorAlertMessage("Unknown server error occured. Please contact the administrator.");
          }
          if (errorResponse.status === 404) {
            showErrorAlertMessage("Server resource cannot be reached or found.");
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onNoClicked = (ev) => {
    setIsAlertVisible(false);
    setIsConfirming(false);
  }

  return (
    <ModalContainer elementId={"commission-contract-modal"}
                    isOpen={isOpen} onRequestClose={onCloseButtonClicked}
                    isLoading={false} 
                    title={customerName} shortDescription={`Choose a proper Location of this Service Profile to transfer the generator [${gensetModel}] ${generatorSerial}.`}>
      <div className={"modal-form"}>
        <div className={"form-message"}>
          <InfoMessage>
            The generator <i>will maintain</i> its current <b>Commission</b> settings at the transfer destination.
          </InfoMessage>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Target Location:<span className={"error-message"}>*</span>
          </label>
        </div>
        <div className={"form-input"}>
          {!targetableLocations || targetableLocations.length === 0 && 
            <ReadonlyField>
              <i>(No targetable location to transfer)</i>
            </ReadonlyField>
          }
          {targetableLocations && targetableLocations.length > 0 && 
            <select name={"targetableLocation"} id={"targetableLocation"} className={"form-control form-select"}
                    disabled={false} value={selectedLocationId}
                    onChange={onTargetLocationSelected}>
              {targetableLocations.map((location, index) =>
                <option value={location['id']} key={index}>{location['name']}</option>
              )}
            </select>
          }
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Location Contact Name:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {selectedLocationContactName}
          </ReadonlyField>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Location Contact No:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {selectedLocationContactNo}
          </ReadonlyField>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Location Address:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            <NullBlankValue value={selectedLocationAddress} />
          </ReadonlyField>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Region:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {getRegionDisplayByName(selectedLocationCityName, selectedLocationTownshipName)}
          </ReadonlyField>
        </div>
      </div>

      <div className={"modal-form"}>
        <div className={"form-message"}>
          <h3>Current Commission</h3>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Commission Type:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {commission && commissionTypes[commission['commissionType']]}
          </ReadonlyField>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Commission Date:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {commission && <Moment date={commission['commissionDate']} format={formatters.datetimeShort} />}
          </ReadonlyField>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Warranty Specification:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {commission && getWarrantySpecifications(commission['warrantyHours'], commission['warrantyMonths'])}
          </ReadonlyField>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Warranty Status:
          </label>
        </div>
        <div className={"form-input"}>
          <ReadonlyField>
            {commission && warrantyStatuses[isUnderWarrantyFromStatus(commission['warrantyStatus'])]}
          </ReadonlyField>
        </div>
      </div>

      <div className={"modal-controls form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                  onClick={onViewProfileClicked}>
            <i className={`fa-solid ${routes.serviceProfiles.faIcon}`}></i>
            View Service Profile
          </button>
          {!isConfirming &&
            <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                    onClick={(ev) => setIsConfirming(true)}>
              <i className={"fa-solid fa-check"}></i>
              Submit
            </button> 
          }
        </div>
        <ConfirmPrompt visible={isConfirming} disabled={isSubmitting}>
          <div>
            Are you sure you want to transfer this generator to <b>{selectedLocationName}</b>?
          </div>
          <ConfirmYesButton isDisabled={isSubmitting} isLoading={isSubmitting} 
                            onClick={onYesSubmitClicked} />
          <ConfirmNoButton isDisabled={isSubmitting} isLoading={false}
                          onClick={onNoClicked} />
        </ConfirmPrompt>
      </div>

    </ModalContainer>
  )
}