import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getViewSettings } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { inventoryServices } from "../../repos/apiServices";
import { authService } from "../../repos/apiServices";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { getListRowSerial } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { ReadonlyField } from "../shared/ReadonlyField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ServiceProfileStatus } from "./ServiceProfileStatus";
import { NullBlankField } from "./NullBlankField";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
import "./ServiceProfilePage.css";
import "./ServiceSearchForm.css";


export function NewServiceProfilePage() {
  //#region States
  const [businessTypes, setBusinessTypes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoading(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response['data']))
      .finally(() => setIsLoading(false));
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem text={routes.newServiceProfile.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"} style={{ marginBottom: '24px' }}>
            <h1>{routes.newServiceProfile.display}</h1>
          </div>

          <TabsPanelProvider initialHeaderElementId={"search-customer-tab"}
                             initialContentPaneElementId={"search-customer-content"}>
            <TabHeader>
              <TabHeaderItem itemElementId={"search-customer-tab"} contentPaneElementId={"search-customer-content"}
                             itemLabel={"Search Customer"} />
              <TabHeaderItem itemElementId={"new-customer-tab"} contentPaneElementId={"new-customer-content"}
                             itemLabel={"Create New"} />
            </TabHeader>
            <TabContent>
              <SearchCustomerTabPane businessTypes={businessTypes}
                                     isLoading={isLoading} setIsLoading={setIsLoading} />
              <NewCustomerTabPane businessTypes={businessTypes}
                                  isLoading={isLoading} setIsLoading={setIsLoading} />
            </TabContent>
          </TabsPanelProvider>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const SearchCustomerTabPane = ({
                                 businessTypes,
                                 isLoading, setIsLoading,
                               }) => {
  //#region States
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  // NOTE(yemon): Reason why the collection array is named 'customerProfiles' instead of
  // 'serviceProfiles' is mainly due to the fact that non-service customers can also be
  // searched and listed in the result.
  const [customerProfiles, setCustomerProfiles] = useState([]);
  const [pagination, setPagination] = useState(null);

  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [companyNameSearch, setCompanyNameSearch] = useState('');
  const [businessTypeSearch, setBusinessTypeSearch] = useState(-1);
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [contactNoSearch, setContactNoSearch] = useState('');
  const [includeProfilesSearch, setIncludeProfilesSearch] = useState(false);

  const [isCreatingProfile, setIsCreatingProfile] = useState(false);
  const [hasRowErrors, setHasRowErrors] = useState(false);
  const [rowErrorMessage, setRowErrorMessage] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Control handlers
  const onIncludeProfilesSearchChanged = (ev) => {
    let _newInclude = !includeProfilesSearch;
    setIncludeProfilesSearch(_newInclude);
  }

  const onSearchClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const validateAndTriggerSearch = (page) => {
    let listPayload = prepareListPayload(page);
    listPayload['is_searching'] = true;
    listPayload['search_options'] = prepareSearchOptions();
    triggerSearch(listPayload);
  }

  const prepareListPayload = (page) => {
    //let settings = getViewSettings(viewSettings.salesInquiries);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        //'pageSize': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
        'page_size': DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const prepareSearchOptions = () => {
    return {
      'customer_name': customerNameSearch.trim(),
      'company_name': companyNameSearch.trim(),
      'business_type_id': businessTypeSearch,
      'contact_name': contactNameSearch.trim(),
      'contact_no': contactNoSearch.trim(),
      'include_profiles': includeProfilesSearch,
    }
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);
    setIsLoading(true);

    inventoryServices.searchCustomersForProfile(listPayload)
      .then((response) => {
        let responseData = response.data;
        updateListingStates(responseData);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onClearClicked = (ev) => {
    // TODO(yemon): Clear the listing and search panel context
    resetSearchFields();
    resetListingStates();
  }

  const updateListingStates = (responseData) => {
    setCustomerProfiles(responseData['data']);
    setPagination(responseData['pagination']);
    // TODO(yemon): Update listing context
  }

  const resetListingStates = () => {
    setCustomerProfiles([]);
    setPagination(null);
  }

  const resetSearchFields = () => {
    setCustomerNameSearch('');
    setCompanyNameSearch('');
    setBusinessTypeSearch(-1);
    setContactNameSearch('');
    setContactNoSearch('');
    setIncludeProfilesSearch(false);
  }

  const onPageClick = (page) => {
    validateAndTriggerSearch(page);
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    validateAndTriggerSearch(page);
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    validateAndTriggerSearch(page);
  }

  const onCreateProfileClicked = (ev, customerId, rowIndex) => {
    let payload = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
    }

    setIsCreatingProfile(true);
    inventoryServices.postCustomerServiceProfile(payload)
      .then((response) => {
        let createdProfile = response['data'];
        /**
         * NOTE(yemon): This "re-update" on the array is commented out, largely due to the fact that
         * re-updating the array state re-renders the individual table rows, which in turn
         * resets the "expanded" state if each row subcomponent.
         * This obviously doesn't produce better user experience than simply redirecting to the
         * ServiceProfilePage after the API postback was successful.
         * So, until a better way is found...
         const updatedProfiles = customerProfiles.map((profile) => {
          if (profile['id'] !== createdProfile['id']) {
            return profile;
          }
          profile = createdProfile;
          return profile;
        });
         setCustomerProfiles(updatedProfiles);
         */

        setTimeout(() => {
          navigate(routes.serviceProfile.url, {
            state: {
              'customerId': createdProfile['id'],
              'serviceProfileId': createdProfile['profileId'],
            }
          });
        }, 600);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            setHasRowErrors(true);
            setRowErrorMessage(errorResponse['error']);
          }
        }
        setIsCreatingProfile(false);
      });
  }

  const onViewProfileClicked = (customerId, profileId) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': profileId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Utilities
  const isFormControlsDisabled = () => {
    return isSearchLoading || isLoading || isCreatingProfile;
  }
  //#endregion

  //#region Render
  return (
    <TabContentPane contentPaneElementId={"search-customer-content"}>
      <form onSubmit={onSearchClicked}>
        <div className={"form-section"}>
          <div className={"entry-form"}>
            <div className={"form-message"}>
              <InfoMessage>
                Find and choose an existing <b>Customer</b> to setup a new <b>Service Profile</b>...
              </InfoMessage>
            </div>
          </div>

          <div className={"entry-form service-profile-form"}>
            <div className={"form-label"}>
              <label htmlFor={"customerNameSearch"}>
                Customer Name:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"companyNameSearch"}>
                Company Name:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"businessTypeSearch"}>
                Business Type:
              </label>
            </div>

            <div className={"form-input"}>
              <input type={"text"} id={"customerNameSearch"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={customerNameSearch} onChange={(ev) => setCustomerNameSearch(ev.target.value)} />
            </div>

            <div className={"form-input"}>
              <input type={"text"} id={"companyNameSearch"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={companyNameSearch} onChange={(ev) => setCompanyNameSearch(ev.target.value)} />
            </div>

            <div className={"form-input"}>
              <select name={"businessTypeSearch"} id={"businessTypeSearch"} className={"form-control form-select"} disabled={isFormControlsDisabled()}
                      value={businessTypeSearch} onChange={(ev) => setBusinessTypeSearch(parseInt(ev.target.value))}>
                <option value={-1}>- Any business types -</option>
                {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
              </select>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"contactNameSearch"}>
                Contact Name:
              </label>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"contactNoSearch"}>
                Contact No:
              </label>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"includeExistingProfiles"}>
                Include existing profiles:
              </label>
            </div>

            <div className={"form-input-r"}>
              <input type={"text"} id={"contactNameSearch"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={contactNameSearch} onChange={(ev) => setContactNameSearch(ev.target.value)} />
            </div>

            <div className={"form-input-r"}>
              <input type={"text"} id={"contactNoSearch"} className={"form-control sm-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={contactNoSearch} onChange={(ev) => setContactNoSearch(ev.target.value)} />
            </div>

            <div className={"form-input-r"}>
              <label htmlFor={"includeExistingProfiles"}>
                <input type={"checkbox"} id={"includeExistingProfiles"} name={"includeExistingProfiles"}
                      checked={includeProfilesSearch} onChange={onIncludeProfilesSearchChanged}
                      disabled={isFormControlsDisabled()} />
              </label>
            </div>
          </div>
        </div>

        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                    onClick={onSearchClicked}>
              {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
              Search
            </button>
            <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                    onClick={onClearClicked}>
              <i className="fa-solid fa-xmark"></i>
              Clear
            </button>
          </div>
        </div>

        <div className={"form-section table-section"}>
          <div className={"data-table service-search-results-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Customer Name</th>
                <th scope={"col"}>Company Name</th>
                <th scope={"col"}>Business Type</th>
                <th scope={"col"}>Contact Name</th>
                <th scope={"col"}>Contact No</th>
              </tr>
              </thead>
              <tbody>
              {isSearchLoading && <TableLoadingIndicator colspan={6} />}

              {customerProfiles && customerProfiles.length > 0 && !(isLoading || isSearchLoading) &&
                customerProfiles.map((customer, index) =>
                  <CustomerProfileRow key={customer['id']} customer={customer} index={index}
                                      currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                      onCreateProfileClicked={onCreateProfileClicked} isCreatingProfile={isCreatingProfile}
                                      onViewProfileClicked={onViewProfileClicked}
                                      hasErrors={hasRowErrors} errorMessage={rowErrorMessage}
                  />
                )
              }

              {!customerProfiles || (customerProfiles.length === 0 && !isSearchLoading &&
                <TableEmptyRow colSpan={6} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                              totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                              onPageClicked={onPageClick}
                              onPrevPageClicked={onPrevPageClicked}
                              onNextPageClicked={onNextPageClicked}
                              isLoading={isSearchLoading}
              />
            }
          </div>
        </div>
      </form>
    </TabContentPane>
  )
  //#endregion
}


const CustomerProfileRow = ({
                              customer, index,
                              currentPage, pageSize,
                              onCreateProfileClicked, isCreatingProfile,
                              onViewProfileClicked,
                              hasErrors, errorMessage,
                            }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [serviceProfile, setServiceProfile] = useState(null);
  const [isCached, setIsCached] = useState(false);

  const onExpandActionClicked = (ev) => {
    ev.preventDefault();
    fetchServiceProfile(customer['id']);
  }

  const fetchServiceProfile = (selectedCustomerId) => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    if (isCached) {
      setIsExpanded(true);
      return;
    }

    // TODO(yemon): Temp, might simply refactor this to 'isExpanded' toggle later on.
    setIsExpanded(true);
    setIsCached(true);
  }

  const hasServiceProfile = () => {
    return customer && customer['profileId'];
  }

  return (
    <>
      <tr>
        <td className={"index-col"}>{getListRowSerial(pageSize, currentPage, index)}</td>
        <td className={isExpanding ? "primary-col expandable-action action-expanding" : "primary-col expandable-action"}>
          <a href={"#"} role={"button"} onClick={onExpandActionClicked}>
            <span>
              {customer['customerName']}
            </span>
            {isExpanding && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isExpanding && !isExpanded && <i className="fa-solid fa-square-plus"></i>}
            {!isExpanding && isExpanded && <i className="fa-solid fa-square-minus"></i>}
            {hasServiceProfile() && <i className="fa-solid fa-address-card" title={"Already has Service Profile."} />}
          </a>
        </td>
        <td className={"secondary-col"}>
          <NullBlankField record={customer} fieldName={'companyName'} />
        </td>
        <td className={"secondary-col"}>
          {customer['businessTypeName']}
        </td>
        <td>
          {customer['contactFullName']}
        </td>
        <td>
          {customer['contactNo']}
        </td>
      </tr>
      {isExpanded &&
        <>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Contact Person NRC:
            </td>
            <td>
              <NullBlankField record={customer} fieldName={'nrc'} />
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Contact Person Designation:
            </td>
            <td>
              <NullBlankField record={customer} fieldName={'designation'} />
            </td>
            <td className={"business-type-col"}></td>
            <td colSpan={2}></td>
          </tr>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Address:
            </td>
            <td>
              <NullBlankField record={customer} fieldName={'address'} />
            </td>
            <td className={"business-type-col"}></td>
            <td colSpan={2}></td>
          </tr>
          {hasServiceProfile() &&
            <tr className={"solid-background"}>
              <td className={"index-col"}></td>
              <td className={"inline-field-label"}>
                Profile status:
              </td>
              <td>
                <ServiceProfileStatus profileStatus={customer['profileStatus']}
                                      profileActiveDatetime={customer['profileActiveDatetime']}
                                      profileInactiveDatetime={customer['profileInactiveDatetime']}
                />
              </td>
              <td className={"business-type-col"}></td>
              <td colSpan={2}></td>
            </tr>
          }

          <tr className={"solid-background bottom-bordered"}>
            <td className={"index-col"}></td>
            <td colSpan={2}>
              <div className={"inline-section-controls"}>
                {hasErrors &&
                  <div className={"inline-field-errors"}>
                    <FieldErrorMessage visible={hasErrors} message={errorMessage} />
                  </div>
                }
                {!hasServiceProfile() &&
                  <button type={"button"} className={"btn btn-primary"} disabled={isCreatingProfile}
                          onClick={(ev) => onCreateProfileClicked(ev, customer['id'], index)}>
                    {isCreatingProfile && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                    {!isCreatingProfile && <i className="fa-solid fa-user-check"></i>}
                    Create Profile
                  </button>
                }
                {hasServiceProfile() &&
                  <button type={"button"} className={"btn btn-secondary"} disabled={isCreatingProfile}
                          onClick={(ev) => onViewProfileClicked(customer['id'], customer['profileId'])}>
                    <i className={"fa-solid fa-address-card"}></i>
                    View Profile
                  </button>
                }
              </div>
            </td>
            <td className={"business-type-col"}></td>
            <td colSpan={2}></td>
          </tr>
        </>
      }
    </>
  )
}


const NewCustomerTabPane = ({
                              businessTypes,
                              isLoading, setIsLoading,
                            }) => {
  //#region States
  const [customerName, setCustomerName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [businessType, setBusinessType] = useState(-1);
  const [address, setAddress] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [contactNrc, setContactNrc] = useState('');
  const [designation, setDesignation] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Utilities
  const isFormControlsDisabled = () => {
    return isLoading || isSubmitting;
  }
  //#endregion

  //#region Control handlers
  const isCustomerFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      customerName: [],
      contactName: [],
      contactNo: [],
    };
    if (!customerName || customerName.trim() === '') {
      _hasErrors = true;
      _formErrors.customerName = ["Customer name is required."];
    }
    if (!contactName || contactName.trim() === '') {
      _hasErrors = true;
      _formErrors.contactName = ["Contact name is required."];
    }
    if (!contactNo || contactNo.trim() === '') {
      _hasErrors = true;
      _formErrors.contactNo = ["Contact number is required."];
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareCustomerPayload = () => {
    return {
      'customer_name': customerName.trim(),
      'company_name': companyName.trim(),
      'business_type_id': businessType,
      'address': address.trim(),
      'contact_fullname': contactName.trim(),
      'contact_no': contactNo.trim(),
      'contact_nrc': contactNrc.trim(),
      'designation': designation.trim(),
      'employee_id': auth.getUserId()['eid'],
    }
  }

  const onSubmitClicked = () => {
    if (!isCustomerFieldsValid()) {
      setHasErrors(true);
      setAlertType(alertTypes.error);
      setAlertMessage("Fix the indicated errors before submitting again.");
      setIsAlertVisible(true);
      return;
    }
    else {
      setHasErrors(false);
      setIsAlertVisible(false);
    }

    let payload = {
      ...prepareCustomerPayload(),
    };
    setIsSubmitting(true);
    inventoryServices.postNewCustomerServiceProfile(payload)
      .then((response) => {
        let createdProfile = response['data']['data'];

        setTimeout(() => {
          navigate(routes.serviceProfile.url, {
            state: {
              'customerId': createdProfile['customerId'],
              'serviceProfileId': createdProfile['profileId'],
            }
          });
        }, 200);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            setAlertType(alertTypes.error);
            setAlertMessage(errorResponse['error']);
            setIsAlertVisible(true);
          }
          if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unknown server error occurred. Please contact the administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }
  //#endregion

  //#region Render
  return (
    <TabContentPane contentPaneElementId={"new-customer-content"}>
      <div className={"form-section"}>
        <div className={"entry-form"}>
          <div className={"form-message"}>
            <WarningMessage>
              You are creating both new <b>Customer</b> and <b>Service Profiles</b> at the same time.<br />
              Please make sure that there are <i><b>no existing Customers already exists</b></i> with the same info provided here.
            </WarningMessage>
          </div>
        </div>

        <div className={"entry-form service-profile-form"}>
          <div className={"form-label"}>
            <label htmlFor={"customerName"}>
              Customer Name:<span className={"error-message"}>*</span>
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"companyName"}>
              Company Name:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"businessType"}>
              Business Type:<span className={"error-message"}>*</span>
            </label>
          </div>

          <label className={"form-label"}>
            <label htmlFor={"address"}>
              Address:
            </label>
          </label>

          <div className={"form-label"}>
            <label htmlFor={"contactName"}>
              Contact Name:<span className={"error-message"}>*</span>
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"contactNo"}>
              Contact No:<span className={"error-message"}>*</span>
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"contactNrc"}>
              Contact NRC:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"designation"}>
              Contact Designation:
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"customerName"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={100}
                   value={customerName} onChange={(ev) => setCustomerName(ev.target.value)} />
            <FieldErrorMessage visible={hasErrors} message={formErrors['customerName']} />
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"companyName"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={100}
                   value={companyName} onChange={(ev) => setCompanyName(ev.target.value)} />
          </div>

          <div className={"form-input"}>
            <select name={"businessType"} id={"businessType"} className={"form-control form-select"} disabled={isFormControlsDisabled()}
                    value={businessType} onChange={(ev) => setBusinessType(parseInt(ev.target.value))}>
              {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
          </div>

          <div className={"form-input"}>
            <textarea id={"address"} className={"form-control"} rows={3} disabled={isFormControlsDisabled()}
                      value={address} onChange={(ev) => setAddress(ev.target.value)} maxLength={300}>
            </textarea>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"contactName"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={100}
                   value={contactName} onChange={(ev) => setContactName(ev.target.value)} />
            <FieldErrorMessage visible={hasErrors} message={formErrors['contactName']} />
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"contactNo"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={50}
                   value={contactNo} onChange={(ev) => setContactNo(ev.target.value)} />
            <FieldErrorMessage visible={hasErrors} message={formErrors['contactNo']} />
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"contactNrc"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={50}
                   value={contactNrc} onChange={(ev) => setContactNrc(ev.target.value)} />
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"designation"} className={"form-control md-field"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()} maxLength={50}
                   value={designation} onChange={(ev) => setDesignation(ev.target.value)} />
          </div>
        </div>
      </div>

      <div className={"form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-primary"} disabled={isFormControlsDisabled()}
                  onClick={onSubmitClicked}>
            {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmitting && <i className="fa-solid fa-check"></i>}
            Submit
          </button>
        </div>
      </div>

      <PageAlert visible={isAlertVisible} type={alertType}
                 onDismissButtonClicked={onAlertDismissButtonClicked}>
        {alertMessage}
      </PageAlert>
    </TabContentPane>
  )
  //#endregion
}
