import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

import { navigableRoutes as routes } from "../../repos/constants";
import { systemServices } from "../../repos/apiServices2";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { NullBlankField } from "../service/NullBlankField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { SortIndicator } from "../shared/SortIndicator";

import "../shared/ListingPage.css";
import "../shared/DataTable.css";


export const UserAccountsSetupPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchUserAccounts();
  }, []);

  const fetchUserAccounts = () => {
    setIsLoading(true);
    systemServices.fetchUserAccounts()
      .then((response) => {
        setUserAccounts(response['data']);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchUserAccounts();
  }

  const onNewClicked = (ev) => {
    // TODO(yemon): Show new entry modal
  }

  const onItemClicked = (ev, user) => {
    ev.preventDefault();
    navigate(routes.userAccountsSetupEntry.url, {
      state: {
        'id': user['id'],
      },
    });
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.manageUsersAndPermissions.display} anchorTo={routes.manageUsersAndPermissions.url} />
              <BreadcrumbItem text={routes.userAccountsSetup.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"rows"}>
            <h1>{routes.userAccountsSetup.display}</h1>

            <div className={"listing-controls"}>
              <div className={"left"}>
                <button className={"btn btn-secondary"} disabled={isLoading}
                        onClick={onRefreshClicked}>
                  {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>

                <button className={"btn btn-primary"} disabled={isLoading}
                        onClick={onNewClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New
                </button>
              </div>
            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <UserAccountsTableHeading />
              </thead>
              <tbody>
              {isLoading && userAccounts &&
                <TableLoadingIndicator colspan={7} />
              }

              {!isLoading && userAccounts && userAccounts.length > 0 &&
                userAccounts.map((account, index) =>
                  <UserAccountsTableRow key={account['id']} index={index}
                                        account={account} onItemClicked={onItemClicked} />
                )
              }

              {!isLoading && userAccounts && userAccounts.length === 0 &&
                <TableEmptyRow colSpan={7} />
              }
              </tbody>
            </table>
          </div>

        </div>
      </main>

    </MasterPageContainer>
  )
  //#endregion
}

const UserAccountsTableHeading = ({}) => {
  return (
    <tr>
      <th scope={"col"} className={"index-col-head fixed-width"}>#</th>
      <th scope={"col"}>
        Full Name
        <SortIndicator isAscending={true} />
      </th>
      <th>Rank</th>
      <th>Branch</th>
      <th>User Account</th>
      <th>Role</th>
      <th>Active?</th>
    </tr>
  )
}

const UserAccountsTableRow = ({ index, account, onItemClicked }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {index + 1}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onItemClicked(ev, account)}>
          {account['fullName']}
        </a>
      </td>
      <td>
        <NullBlankField record={account} fieldName={'rankName'} />
      </td>
      <td>
        <NullBlankField record={account} fieldName={'branchName'} />
      </td>
      <td>
        <NullBlankField record={account} fieldName={'accountUsername'} />
      </td>
      <td>
        {account['roleName']}
      </td>
      <td>
        {account['isActive'] === true ? "Yes" : "No"}
      </td>
    </tr>
  )
}
