import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { setupServices } from "../../repos/apiServices2";
import { handleApiErrorAlert } from "../../repos/httpUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { InfoMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ErrorPrompt } from "../shared/PromptMessages";
import { PromptButton } from "../shared/PromptMessages";

import "./SetupEntryPage.css";

export const BusinessTypeSetupEntryPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);

  const [usages, setUsages] = useState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");
  const [showsUsagePrompt, setShowsUsagePrompt] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    const state = location.state;
    if (state && state['id']) {
      setIsEditing(true);
      const businessTypeId = state['id'];
      fetchBusinessType(businessTypeId);
    }
  }, []);

  const fetchBusinessType = (businessTypeId) => {
    setIsLoading(true);
    setupServices.fetchBusinessType(businessTypeId)
      .then((response) => {
        const responseData = response['data'];
        prepareBusinessTypeDetails(responseData);
      })
      .catch((error) => {
        console.error(error);
        navigate(routes.businessTypesSetup.url);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareBusinessTypeDetails = (businessType) => {
    if (!businessType) {
      return;
    }

    if (businessType['id']) {
      setId(businessType['id']);
      setIsEditing(true);
    }
    else {
      setId(null);
      setIsEditing(false);
    }
    setName(businessType['name'])
    setDescription(businessType['description'] === null ? "" : businessType['description']);
    setStatus(businessType['isActive']);
  }
  //#endregion

  //#region Control handlers
  const isFormDisabled = () => {
    return isSaving || isLoading || isChecking;
  }

  const onNameChanged = (ev) => {
    setName(ev.target.value);
  }

  const onDescriptionChanged = (ev) => {
    setDescription(ev.target.value);
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      name: "",
    };
    if (name.trim() === "") {
      _formErrors.name = "Business type name is required.";
      _hasErrors = true;
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const onSaveClicked = (ev) => {
    if (!isFormFieldsValid()) {
      setHasErrors(true);
      return;
    }
    else {
      setHasErrors(false);
    }

    let payload = {
      'id': id !== null ? id : null,
      'name': name.trim(),
      'description': description && description.trim() !== "" ? description : null,
      'isActive': status,
      'createdById': auth.getUserId()['eid'],
    };

    setIsSaving(true);
    setupServices.submitBusinessType(payload)
      .then((response) => {
        const responseData = response['data'];
        if (responseData['success'] === true) {
          setAlertType(alertTypes.info);
          setAlertMessage(responseData['message']);
          setIsAlertVisible(true);
          prepareBusinessTypeDetails(responseData['businessType']);
        }
        else {
          if (responseData['hasUsages'] === true) { 
            setIsAlertVisible(false);
            setShowsUsagePrompt(true);
            setUsages(responseData['usages']);
          }
          else {
            setAlertType(alertTypes.error);
            setAlertMessage("Unable to save the business type.");
            setIsAlertVisible(true);
          }
        }
      })
      .catch((error) => {
        let errorResponse = error['response'];
        handleApiErrorAlert(errorResponse, setAlertType, setAlertMessage, setIsAlertVisible);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container-grid"}>
        <div className={"content-area content-area-main"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.manageReferences.displayShort} anchorTo={routes.manageReferences.url} />
              <BreadcrumbItem text={routes.businessTypesSetup.displayShort} anchorTo={routes.businessTypesSetup.url} />
              <BreadcrumbItem text={routes.businessTypesSetupEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>
              {routes.businessTypesSetupEntry.display}
            </h1>
          </div>

          <div className={"form-section"}>
            <div className={"entry-form business-type-setup-entry-form"}>
              <div className={"form-label-wide"}>
                <label htmlFor={"name"}>
                  Name:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label-wide"}>
                <label htmlFor={"description"}>
                  Description:
                </label>
              </div>

              <div className={"form-label-wide"}>
                <label htmlFor={"status"}>
                  Active:
                </label>
              </div>

              <div className={"form-input-wide"}>
                <input type={"text"} id={"name"} className={"form-control"} autoFocus={true}
                       autoComplete={"none"} maxLength={50} disabled={isFormDisabled()} 
                       value={name} onChange={onNameChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors["name"]} />
              </div>

              <div className={"form-input-wide"}>
                <textarea id={"description"} className={"form-control"} rows={3} autoComplete={"none"} 
                          maxLength={150} disabled={isFormDisabled()}
                          value={description} onChange={onDescriptionChanged}>
                </textarea>
              </div>

              <div className={"form-input-wide"}>
                <label className={"option-field"}>
                  <input type={"radio"} id={"statusActive"} name={"statusActive"} disabled={isFormDisabled()}
                         value={true} checked={status === true} onChange={(ev) => setStatus(true)} />
                  Yes
                </label>
                <label className={"option-field"}>
                  <input type={"radio"} id={"statusActive"} name={"statusActive"} disabled={isFormDisabled()}
                         value={false} checked={status === false} onChange={(ev) => setStatus(false)} />
                  No
                </label>
              </div>
            </div>
          </div>

          <div className={"form-button-controls"}>
            <ErrorPrompt visible={showsUsagePrompt}>
              <p>
                This business type is currently being used in one or more modules. (See the <b>Usage</b> on the right)
              </p>
              <p>
                It cannot be disabled at the moment.
              </p>
              <PromptButton disabled={isFormDisabled()} onClick={(ev) => setShowsUsagePrompt(false)}>
                <i className={"fa-solid fa-check"}></i>
                Ok
              </PromptButton>
            </ErrorPrompt>
            {!showsUsagePrompt && 
              <div className={"left-side"}>
                <button type={"button"} className={"btn btn-primary"} disabled={isFormDisabled()}
                        onClick={onSaveClicked}>
                  {isSaving && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isSaving && <i className={"fa-solid fa-check"}></i>}
                  Save
                </button>
              </div>
            }
          </div>
        </div>

        <div className={"content-area content-area-side"}>
          <BusinessTypeReferenceWidget businessTypeId={id} businessTypeName={name} 
                                       usages={usages} setUsages={setUsages}
                                       isChecking={isChecking} setIsChecking={setIsChecking}
                                       isFormDisabled={isFormDisabled} isEditing={isEditing} />
        </div>

        <PageAlert visible={isAlertVisible} type={alertType}
                   onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
          {alertMessage}
        </PageAlert>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const BusinessTypeReferenceWidget = ({ 
                                      businessTypeId, businessTypeName, 
                                      usages, setUsages,
                                      isChecking, setIsChecking, 
                                      isFormDisabled, isEditing,
                                    }) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onCheckClicked = (ev) => {
    if (!businessTypeId) {
      return;
    }

    setIsChecking(true);
    let _usages = [];
    setupServices.checkBusinessTypeUsages(businessTypeId)
      .then((response) => {
        _usages = response['data'];
      })
      .catch((error) => {
        let errorResponse = error['response'];
        setErrorMessage(errorResponse['message']);
        setHasErrors(true);
      })
      .finally(() => {
        setUsages(_usages.slice());
        setIsChecking(false);
      });
  }

  const getUsageDisplay = (usage) => {
    if (usage['type'] === businessTypeUsages.none) {
      return "None";
    }
    else {
      const label = businessTypeUsages[usage['type']];
      const count = usage['count'];
      return `${count} ${count > 1 ? label.plural : label.singular}`;
    }
  }

  return (
    <div className={"info-panel"}>
      <h2>Usage</h2>
      <div className={"panel-contents"}>
        {!isEditing && 
          <span style={{ fontStyle: 'italic' }}>None</span>
        }
        {isEditing && isChecking && 
          <span style={{ fontStyle: 'italic' }}>
            Identifying usage of <b>{businessTypeName}</b>...
          </span>
        }
        {isEditing && !isChecking && usages.length === 0 && 
          <span>
            <i>(Click the button below to identify...)</i>
          </span>
        }
      </div>
      {usages && usages.length > 0 &&
        <div className={"panel-contents"}>
          {usages.map((usage, _) => 
            <UsageDisplayLabel key={usage['type']} usage={usage} />
          )}
        </div>
      }
      {hasErrors && 
        <div className={"panel-contents"}>
          <span style={{ color: 'red' }}>
            {errorMessage}
          </span>
        </div>
      }
      <div className={"panel-controls"}>
        <button role={"button"} className={"btn btn-secondary"}
                disabled={isFormDisabled() || !isEditing} onClick={onCheckClicked}>
          {isChecking && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
          {!isChecking && <i className={"fa-solid fa-search"}></i>}
          Check
        </button>
      </div>
    </div>
  )
}

const businessTypeUsages = {
  none: 0,
  1: {
    singular: "Customer Profile",
    plural: "Customer Profiles",
    icon: routes.customersList.faIcon,
  },
  2: {
    singular: "Sales Inquiry",
    plural: "Sales Inquiries",
    icon: routes.salesInquiriesList.faIcon,
  },
};

const UsageDisplayLabel = ({ usage }) => {
  const [label, setLabel] = useState({singular: "", plural: "", icon: ""});
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLabel(businessTypeUsages[usage['type']]);
    setCount(usage['count']);
  }, []);

  return (
    <>
      {usage['type'] === businessTypeUsages.none && 
        <div className="usage-label">
          <i className={"fa-solid fa-circle"}></i>None
        </div>
      }

      {usage['type'] !== businessTypeUsages.none && 
        <div className="usage-label">
          <i className={`fa-solid ${label.icon}`}></i>
          {`${count} ${count > 1 ? label.plural : label.singular}`}
        </div>

      }
    </>
  )
}
