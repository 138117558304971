import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { systemServices } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ControlsGrid } from "../shared/ControlsGrid";
import { ControlsLoadingIndicator } from "../shared/ControlsGrid";
import { ControlsEmptyIndicator } from "../shared/ControlsGrid";
import { ControlsGroup } from "../shared/ControlsGrid";
import { ControlsItems } from "../shared/ControlsGrid";
import { ControlButton } from "../shared/ControlsGrid";

import "../shared/ListingPage.css";

export const ServiceReportsPage = () => {
  //#region States & Effects
  const navigate = useNavigate();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  
  const [allowServiceGeneratorsGroup, setAllowServiceGeneratorsGroup] = useState(false);
  const [allowOverWarrantyGenerators, setAllowOverWarrantyGenerators] = useState(false);
  const [allowGeneratorWarrantyStatuses, setAllowGeneratorWarrantyStatuses] = useState(false);

  const [allowServiceJobsGroup, setAllowServiceJobsGroup] = useState(false);
  const [allowWorkDoneSummaries, setAllowWorkDoneSummaries] = useState(false);

  useEffect(() => {
    fetchReportPermissions();
  }, []);

  const fetchReportPermissions = () => {
    const userId = auth.getUserId();
    systemServices.postReportPermissions(userId)
      .then((response) => {
        const service = response['data']['service'];
        const _overWarrantyGenerators = service['overWarrantyGenerators'];
        const _generatorWarrantyStatuses = service['generatorWarrantyStatuses'];
        const _workDoneSummaries = service['workDoneSummaries'];

        setAllowOverWarrantyGenerators(_overWarrantyGenerators);
        setAllowGeneratorWarrantyStatuses(_generatorWarrantyStatuses);
        setAllowWorkDoneSummaries(_workDoneSummaries);
        if (_overWarrantyGenerators || _generatorWarrantyStatuses) {
          setAllowServiceGeneratorsGroup(true);
        }
        if (_workDoneSummaries) {
          setAllowServiceJobsGroup(true);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //#endregion

  //#region Control handlers
  const isEmpty = () => {
    return !allowServiceGeneratorsGroup && !allowServiceJobsGroup;
  }

  const onClickOverWarrantyGenerators = (ev) => {
    navigate(routes.serviceReports_OverWarrantyGenerators.url);
  }

  const onClickGeneratorsWarrantyStatus = (ev) => {
    navigate(routes.serviceReports_WarrantyStatuses.url);
  }

  const onClickWorkDoneSummary = (ev) => {
    navigate(routes.serviceReports_WorkDoneSummary.url);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceReports.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.serviceReports.displayShort}</h1>
          </div>

          <ControlsGrid isLoading={isLoading} isEmpty={isEmpty()} >
            {isLoading &&
              <ControlsLoadingIndicator />
            }

            {isEmpty() && <ControlsEmptyIndicator />}

            {allowServiceGeneratorsGroup &&
              <>
                <ControlsGroup title={"Service Generators"} />
                <ControlsItems>
                  {allowOverWarrantyGenerators &&
                    <ControlButton icon={routes.serviceReports_OverWarrantyGenerators.faIcon}
                                  label={routes.serviceReports_OverWarrantyGenerators.displayShort}
                                  disabled={false} onClick={onClickOverWarrantyGenerators} />
                  }
                  {allowGeneratorWarrantyStatuses &&
                    <ControlButton icon={routes.serviceReports_WarrantyStatuses.faIcon}
                                  label={routes.serviceReports_WarrantyStatuses.displayShort}
                                  disabled={false} onClick={onClickGeneratorsWarrantyStatus} />
                  }
                </ControlsItems>
              </>
            }

            {allowServiceJobsGroup &&
              <>
                <ControlsGroup title={"Service Jobs"} />
                <ControlsItems>
                  {allowWorkDoneSummaries &&
                    <ControlButton icon={routes.serviceReports_WorkDoneSummary.faIcon}
                                  label={routes.serviceReports_WorkDoneSummary.displayShort}
                                  disabled={false} onClick={onClickWorkDoneSummary} />
                  }
                </ControlsItems>
              </>
            }

          </ControlsGrid>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
