import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { authService as authService2 } from "../../repos/apiServices2";
import { inventoryServices } from "../../repos/apiServices";
import { technicalServices } from "../../repos/apiServices2";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { authService } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";
import { getWarrantySpecifications } from "../../repos/generatorUtilities";
import { getListRowSerial } from "../../repos/utilities";
import { getRegionDisplayByName } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { NullBlankValue } from "./NullBlankField";
import { NullBlankField } from "./NullBlankField";
import { ConfirmPrompt } from "../shared/PromptMessages";
import { ConfirmYesButton } from "../shared/PromptMessages";
import { ConfirmNoButton } from "../shared/PromptMessages";
import { LocationsTransferModal } from "./LocationsTransferModal";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";

const commissionTypes = refs.inventory.serviceGeneratorCommissionType;
const warrantyStatuses = refs.inventory.serviceGeneratorWarrantyStatus;

export const ServiceGeneratorTransferPage = () => {
  //#region States
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedGeneratorId, setSelectedGeneratorId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [gensetModel, setGensetModel] = useState("");
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [commission, setCommission] = useState({});

  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [targetableLocations, setTargetableLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    setIsLoading(true);
    ifGeneratorTransferAllowed(() => {
      const state = location.state;
      if (!state) {
        navigate(routes.serviceProfiles.url);
      }
      // NOTE(yemon): does it matter whether the customer+generator details were not fetched on load?
      const _customerId = state['customerId'];
      const _customerName = state['customerName'];
      const _profileId = state['profileId'];
      const _generatorId = state['generatorId'];
      const _generatorSerial = state['generatorSerial'];
      if (!_customerId || !_profileId || !_generatorId) {
        navigate(routes.serviceProfiles.url);
      }
      setSelectedCustomerId(_customerId);
      setCustomerName(_customerName);
      setSelectedProfileId(_profileId);
      setSelectedGeneratorId(_generatorId);
      setGeneratorSerial(_generatorSerial);

      fetchServiceGenerator(_profileId, _generatorId);
    });
  }, []);

  const ifGeneratorTransferAllowed = (callback) => {
    let isAllowed = false;
    authService2.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const _servicePermissions = response['data']['service'];
        isAllowed = _servicePermissions['allowGeneratorTransfer'];
        if (isAllowed && callback) {
          callback();
        }
        else {
          navigate(routes.serviceProfiles.url);
        }
      });
  }

  const fetchServiceGenerator = (profileId, generatorId) => {
    let params = {
      'uid': auth.getUserId(),
      'service_profile_id': profileId,
      'service_generator_id': generatorId,
    };
    technicalServices.fetchServiceProfileGenerator(params)
      .then((response) => {
        let _serviceGenerator = response['data'];
        setCustomerName(_serviceGenerator['serviceLocation']['profile']['customer']['customerName']);
        const _locationId = _serviceGenerator['serviceLocationId'];
        setLocationId(_locationId);
        setLocationName(_serviceGenerator['serviceLocation']['name']);
        setGensetModel(_serviceGenerator['gensetModel']);
        setGeneratorSerial(_serviceGenerator['generatorSerial']);

        setCommission({
          'commissionId': _serviceGenerator['commissionId'],
          'commissionType': _serviceGenerator['commissionType'],
          'commissionDate': _serviceGenerator['commissionDate'],
          'warrantyMonths': _serviceGenerator['warrantyMonths'],
          'warrantyTargetDate': _serviceGenerator['warrantyTargetDate'],
          'warrantyHours': _serviceGenerator['warrantyHours'],
          'warrantyStatus': _serviceGenerator['warrantyStatus'],
        });

        return fetchTargetableLocations(profileId, _locationId);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            navigate(routes.serviceProfiles.url);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const fetchTargetableLocations = (profileId, locationId) => {
    setIsLoadingLocations(true);
    technicalServices.fetchServiceProfileTargetableLocations(profileId, locationId)
      .then((response) => {
        let _locations = response['data'];
        setTargetableLocations(_locations);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            setTargetableLocations([]);
          }
        }
      })
      .finally(() => {
        setIsLoadingLocations(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    navigate(routes.serviceProfile.url, {
      state: {
        'customerId': selectedCustomerId,
        'serviceProfileId': selectedProfileId,
      }
    });
  }

  const onGeneratorSerialClicked = (ev) => {
    navigate(routes.serviceGenerator.url, {
      state: {
        'customerId': selectedCustomerId,
        'profileId': selectedProfileId,
        'generatorId': selectedGeneratorId,
      }
    });
  }

  const onLocationSelected = (ev) => {
    //
  }

  const showErrorAlertMessage = (message) => {
    if (!message || message === "") {
      setIsAlertVisible(false);
      return;
    }
    setAlertType(alertTypes.error);
    setAlertMessage(message);
    setIsAlertVisible(true);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {generatorSerial}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.serviceGeneratorTransfer.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.serviceGeneratorTransfer.display}</h1>
          </div>

          <div className={"form-section"}>
            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Customer Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Genset Model:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {customerName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {gensetModel}
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Location:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Generator Serial:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {locationName}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {generatorSerial}
                </ReadonlyField>
              </div>
            </div>
          </div>

          <TabsPanelProvider initialHeaderElementId={"location-transfer-tab"} 
                             initialContentPaneElementId={"location-transfer-content"}>
            <TabHeader>
              <TabHeaderItem itemElementId={"location-transfer-tab"} contentPaneElementId={"location-transfer-content"} 
                             itemLabel={"Service Location"} />
              <TabHeaderItem itemElementId={"profile-transfer-tab"} contentPaneElementId={"profile-transfer-content"} 
                             itemLabel={"Customer/Service Profile"} />
            </TabHeader>
            <TabContent>
              <LocationTransferTabPane customerId={selectedCustomerId} profileId={selectedProfileId} serviceGeneratorId={selectedGeneratorId}
                                       isLoading={isLoading || isLoadingLocations} commission={commission}
                                       locationName={locationName} targetableLocations={targetableLocations}
                                       selectedLocationId={selectedLocationId} setSelectedLocationId={setSelectedLocationId}
                                       onLocationSelected={onLocationSelected} 
                                       setIsAlertVisible={setIsAlertVisible} showErrorAlertMessage={showErrorAlertMessage} />
              <ProfileTransferTabPane customerId={selectedCustomerId} profileId={selectedProfileId} commission={commission} 
                                      selectedGeneratorId={selectedGeneratorId} 
                                      gensetModel={gensetModel} generatorSerial={generatorSerial}
                                      isLoading={isLoading || isLoadingLocations}
                                      setIsAlertVisible={setIsAlertVisible} showErrorAlertMessage={showErrorAlertMessage} />
            </TabContent>
          </TabsPanelProvider>

          <PageAlert visible={isAlertVisible} type={alertType}
                    onDismissButtonClicked={(_) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const LocationTransferTabPane = ({ 
                                  customerId, profileId, serviceGeneratorId,
                                  isLoading, commission, locationName, targetableLocations,
                                  selectedLocationId, setSelectedLocationId, onLocationSelected,
                                  setIsAlertVisible, showErrorAlertMessage,
                                }) => {
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [selectedLocationContactName, setSelectedLocationContactName] = useState("");
  const [selectedLocationContactNo, setSelectedLocationContactNo] = useState("");
  const [selectedLocationAddress, setSelectedLocationAddress] = useState("");
  const [selectedLocationCityName, setSelectedLocationCityName] = useState("");
  const [selectedLocationTownshipName, setSelectedLocationTownshipName] = useState(null);

  const [isConfirming, setIsConfirming] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (targetableLocations.length > 0) {
      const _firstLocation = targetableLocations[0];
      setSelectedLocationId(_firstLocation['id']);
      setSelectedLocationName(_firstLocation['name']);
      setSelectedLocationContactName(_firstLocation['contactFullName']);
      setSelectedLocationContactNo(_firstLocation['contactNo']);
      setSelectedLocationAddress(_firstLocation['address']);
      setSelectedLocationCityName(_firstLocation['cityName']);
      setSelectedLocationTownshipName(_firstLocation['townshipName']);
    } 
    else {
      setSelectedLocationId(null);
      setSelectedLocationName("(NA)");
      setSelectedLocationContactName("(NA)");
      setSelectedLocationContactNo("(NA)");
      setSelectedLocationAddress("(NA)");
      setSelectedLocationCityName("(NA)");
      setSelectedLocationTownshipName(null);
    }
  }, [targetableLocations]);

  const onTargetLocationSelected = (ev) => {
    if (targetableLocations.length === 0) {
      setSelectedLocationId(null);
      setSelectedLocationName("(NA)");
      setSelectedLocationContactName("(NA)");
      setSelectedLocationContactNo("(NA)");
      setSelectedLocationAddress("(NA)");
      setSelectedLocationCityName("(NA)");
      setSelectedLocationTownshipName(null);
    }
    else {
      const _selectedLocation = targetableLocations.find((location) => location['id'] === ev.target.value);
      if (_selectedLocation) {
        setSelectedLocationId(_selectedLocation['id']);
        setSelectedLocationName(_selectedLocation['name']);
        setSelectedLocationContactName(_selectedLocation['contactFullName']);
        setSelectedLocationContactNo(_selectedLocation['contactNo']);
        setSelectedLocationAddress(_selectedLocation['address']);
        setSelectedLocationCityName(_selectedLocation['cityName']);
        setSelectedLocationTownshipName(_selectedLocation['townshipName']);
      }
      else {
        setSelectedLocationId(null);
        setSelectedLocationName("(NA)");
        setSelectedLocationContactName("(NA)");
        setSelectedLocationContactNo("(NA)");
        setSelectedLocationAddress("(NA)");
        setSelectedLocationCityName("(NA)");
        setSelectedLocationTownshipName(null);
      }
    }

    if (onLocationSelected) {
      onLocationSelected(ev);
    }
  }

  const onViewLocationsClicked = (ev) => {
    navigate(routes.manageProfileLocations.url, {
      state: {
        'customerId': customerId,
        'serviceProfileId': profileId,
      }
    });
  }

  const onYesSubmitClicked = (ev) => {
    setIsSubmitting(true);
    const payload = {
      'serviceGeneratorId': serviceGeneratorId,
      'serviceLocationId': selectedLocationId,
      'employeeId': auth.getUserId()['eid'],
    };
    technicalServices.trasnferServiceGeneratorLocation(payload)
      .then((response) => {
        setIsAlertVisible(false);
        navigate(routes.serviceGenerator.url, {
          state: {
            'generatorId': serviceGeneratorId,
            'profileId': profileId,
            'customerId': customerId,
          },
        });
      })
      .catch((error) => {
        const errorResponse = error['response'];
        const errorData = errorResponse['data'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            showErrorAlertMessage(errorData['error']);
          }
          if (errorResponse.status === 500) {
            showErrorAlertMessage("Unknown server error occured. Please contact the administrator.");
          }
          if (errorResponse.status === 404) {
            showErrorAlertMessage("Server resource cannot be reached or found.");
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onNoClicked = (ev) => {
    setIsAlertVisible(false);
    setIsConfirming(false);
  }

  return (
    <TabContentPane contentPaneElementId={"location-transfer-content"}>
      <div className={"form-section"}>
        <div className={"entry-form generator-transfer-form"}>
          <div className={"form-message"}>
            <InfoMessage>
              Choose another location under the same <b>Service Profile</b> to transfer this generator to.
            </InfoMessage>
          </div>

          <div className={"form-label"}>
            <label>
              Target Location:<span className={"error-message"}>*</span>
            </label>
          </div>

          <div className={"form-label"}>
            <label>
              Location Address:
            </label>
          </div>

          <div className={"form-label"}>
            <label>
              Region:
            </label>
          </div>

          <div className={"form-input"}>
            {!targetableLocations || targetableLocations.length === 0 && 
              <ReadonlyField>
                <i>(No targetable location to transfer)</i>
              </ReadonlyField>
            }
            {targetableLocations && targetableLocations.length > 0 && 
              <select name={"targetableLocation"} id={"targetableLocation"} className={"form-control form-select"}
                      disabled={isLoading} value={selectedLocationId}
                      onChange={onTargetLocationSelected}>
                <optgroup label={`From ${locationName} to...`}>
                {targetableLocations.map((location, index) =>
                  <option value={location['id']} key={index}>{location['name']}</option>
                )}
                </optgroup>
              </select>
            }
          </div>

          <div className={"form-input"}>
            <ReadonlyField>
              <NullBlankValue value={selectedLocationAddress} />
            </ReadonlyField>
          </div>

          <div className={"form-input"}>
            <ReadonlyField>
              {getRegionDisplayByName(selectedLocationCityName, selectedLocationTownshipName)}
            </ReadonlyField>
          </div>

          <div className={"form-label-r"}>
            <label>
              Location Contact Name:
            </label>
          </div>

          <div className={"form-label-r"}>
            <label>
              Location Contact No:
            </label>
          </div>

          <div className={"form-input-r"}>
            <ReadonlyField>
              {selectedLocationContactName}
            </ReadonlyField>
          </div>

          <div className={"form-input-r"}>
            <ReadonlyField>
              {selectedLocationContactNo}
            </ReadonlyField>
          </div>
        </div>

      </div>

      <div className={"form-section"}>
        <h3>Current Commission</h3>

        <div className={"entry-form generator-transfer-form"}>
          <div className={"form-label"}>
            Commission Type:
          </div>

          <div className={"form-label"}>
            Warranty Specifications:
          </div>

          <div className={"form-input"}>
            <ReadonlyField>
              {commission && commissionTypes[commission['commissionType']]}
            </ReadonlyField>
          </div>

          <div className={"form-input"}>
            <ReadonlyField>
              {commission && getWarrantySpecifications(commission['warrantyHours'], commission['warrantyMonths'])}
            </ReadonlyField>
          </div>

          <div className={"form-label-r"}>
            <div className={"form-label"}>
              Commission Date:
            </div>
          </div>

          <div className={"form-label-r"}>
            <div className={"form-label"}>
              Warranty Status:
            </div>
          </div>

          <div className={"form-input-r"}>
            <ReadonlyField>
              {commission && <Moment date={commission['commissionDate']} format={formatters.datetimeShort} />}
            </ReadonlyField>
          </div>

          <div className={"form-input-r"}>
            <ReadonlyField>
              {commission && warrantyStatuses[isUnderWarrantyFromStatus(commission['warrantyStatus'])]}
            </ReadonlyField>
          </div>

        </div>
      </div>

      <div className={"form-button-controls"}>
        <div className={"left-side"}>
          <button className={"btn btn-secondary"} disabled={isSubmitting}
                  onClick={onViewLocationsClicked}>
            <i className={"fa-solid fa-location-dot"}></i>
            View Locations
          </button>

          {!isConfirming && 
            <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                    onClick={(ev) => setIsConfirming(true)}>
              <i className={"fa-solid fa-check"}></i>
              Submit
            </button> 
          }
        </div>

        <ConfirmPrompt visible={isConfirming} disabled={isSubmitting}>
          <div>
            Are you sure you want to transfer this generator to <b>{selectedLocationName}</b>?
          </div>
          <ConfirmYesButton isDisabled={isSubmitting} isLoading={isSubmitting} 
                            onClick={onYesSubmitClicked} />
          <ConfirmNoButton isDisabled={isSubmitting} isLoading={false}
                          onClick={onNoClicked} />
        </ConfirmPrompt>
      </div> 
    </TabContentPane>
  )
}

const ProfileTransferTabPane = ({
                                  customerId, profileId, commission,
                                  selectedGeneratorId, gensetModel, generatorSerial, isLoading, 
                                  setIsAlertVisible, showErrorAlertMessage,
                                }) => {
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);

  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [companyNameSearch, setCompanyNameSearch] = useState('');
  const [businessTypeSearch, setBusinessTypeSearch] = useState(-1);
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [contactNoSearch, setContactNoSearch] = useState('');

  const [isSearching, setIsSearching] = useState(false);
  const [customerProfiles, setCustomerProfiles] = useState([]);
  const [pagination, setPagination] = useState(null);

  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const auth = useAuth();

  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoadingBusinessTypes(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response['data']))
      .finally(() => setIsLoadingBusinessTypes(false));
  }

  const isFormControlsDisabled = () => {
    return isLoading || isLoadingBusinessTypes;
  }

  const onClearClicked = (ev) => {
    resetSearchFields();
    resetListingStates();
  }

  const onSearchClicked = (ev) => {
    ev.preventDefault();
    validateAndTriggerSearch(1);
  }

  const validateAndTriggerSearch = (page) => {
    let payload = {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': DEFAULT_LIST_PAGE_SIZE,
      },
    };
    payload['source_profile_id'] = profileId;
    payload['search_options'] = prepareSearchOptions();

    resetListingStates();
    setIsSearching(true);
    technicalServices.fetchServiceProfileTargetableCustomers(payload)
      .then((response) => {
        updateListingStates(response['data']);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsSearching(false);
      });
  }

  const prepareSearchOptions = () => {
    return {
      'customer_name': customerNameSearch.trim(),
      'company_name': companyNameSearch.trim(),
      'business_type_id': businessTypeSearch,
      'contact_name': contactNameSearch.trim(),
      'contact_no': contactNoSearch.trim(),
    };
  }

  const updateListingStates = (responseData) => {
    setCustomerProfiles(responseData['data']);
    setPagination(responseData['pagination']);
  }

  const resetListingStates = () => {
    setCustomerProfiles([]);
    setPagination(null);
  }

  const resetSearchFields = () => {
    setCustomerNameSearch('');
    setCompanyNameSearch('');
    setBusinessTypeSearch(-1);
    setContactNameSearch('');
    setContactNoSearch('');
  }

  const onPageClick = (page) => {
    validateAndTriggerSearch(page);
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    validateAndTriggerSearch(page);
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    validateAndTriggerSearch(page);
  }

  //#region Location selection modal
  const [isLocationsModalOpened, setIsLocationsModalOpened] = useState(false);
  const [selectedServiceProfile, setSelectedServiceProfile] = useState(null);
  
  const onChooseProfileClicked = (ev, profile) => {
    ev.preventDefault();
    setSelectedServiceProfile(profile);
    locationsModal.onOpenClicked();
  }

  const locationsModal = {
    onOpenClicked: function() {
      setIsLocationsModalOpened(true);
    },

    onCloseClicked: function() {
      setIsLocationsModalOpened(false);
      setSelectedServiceProfile(null);
    },
  }
  //#endregion

  return (
    <TabContentPane contentPaneElementId={"profile-transfer-content"}>
      <form onSubmit={onSearchClicked}>
        <div className={"form-section"}>
          <div className={"entry-form generator-transfer-form"}>
            <div className={"form-message"}>
              <InfoMessage>
                Find and choose a <b>Service Profile</b> first...
              </InfoMessage>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"customerNameSearch"}>
                Customer Name:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"companyNameSearch"}>
                Company Name:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"businessTypeSearch"}>
                Business Type:
              </label>
            </div>

            <div className={"form-input"}>
              <input type={"text"} id={"customerNameSearch"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={customerNameSearch} onChange={(ev) => setCustomerNameSearch(ev.target.value)} />
            </div>

            <div className={"form-input"}>
              <input type={"text"} id={"companyNameSearch"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={companyNameSearch} onChange={(ev) => setCompanyNameSearch(ev.target.value)} />
            </div>

            <div className={"form-input"}>
              <select name={"businessTypeSearch"} id={"businessTypeSearch"} className={"form-control form-select"} disabled={isFormControlsDisabled()}
                      value={businessTypeSearch} onChange={(ev) => setBusinessTypeSearch(parseInt(ev.target.value))}>
                <option value={-1}>- Any business types -</option>
                {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
              </select>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"contactNameSearch"}>
                Contact Name:
              </label>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"contactNoSearch"}>
                Contact No:
              </label>
            </div>

            <div className={"form-input-r"}>
              <input type={"text"} id={"contactNameSearch"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={contactNameSearch} onChange={(ev) => setContactNameSearch(ev.target.value)} />
            </div>

            <div className={"form-input-r"}>
              <input type={"text"} id={"contactNoSearch"} className={"form-control sm-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                    value={contactNoSearch} onChange={(ev) => setContactNoSearch(ev.target.value)} />
            </div>
          </div>
        </div>

        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                    onClick={onSearchClicked}>
              {isSearching && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isSearching && <i className="fa-solid fa-magnifying-glass"></i>}
              Search
            </button>
            <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                    onClick={onClearClicked}>
              <i className="fa-solid fa-xmark"></i>
              Clear
            </button>
          </div>
        </div>
      </form>

      <LocationsTransferModal isOpen={isLocationsModalOpened} 
                              onRequestClose={locationsModal.onCloseClicked} 
                              selectedServiceProfile={selectedServiceProfile} selectedGeneratorId={selectedGeneratorId}
                              gensetModel={gensetModel} generatorSerial={generatorSerial}
                              serviceProfile={selectedServiceProfile} commission={commission} profileId={profileId}
                              setIsAlertVisible={setIsAlertVisible} showErrorAlertMessage={showErrorAlertMessage} />

      <div className={"form-section table-section"}>
        <div className={"data-table service-search-results-table"}>
          <table>
            <thead>
            <tr>
              <th scope={"col"} className={"index-col-head"}>#</th>
              <th scope={"col"}>Customer Name</th>
              <th scope={"col"}>Company Name</th>
              <th scope={"col"}>Business Type</th>
              <th scope={"col"}># of Locations</th>
            </tr>
            </thead>
            <tbody>
              {isSearching && <TableLoadingIndicator colspan={5} />}

              {!isSearching && (customerProfiles && customerProfiles.length > 0) && 
                customerProfiles.map((customerProfile, index) =>
                  <CustomerProfileRow key={customerProfile['profileId']} serviceProfile={customerProfile} index={index}
                                      currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                      onRowClicked={onChooseProfileClicked} />
                )
              }

              {!isSearching && (customerProfiles && customerProfiles.length === 0) && 
                <TableEmptyRow colSpan={5} />
              }
            </tbody>
          </table>
          {pagination &&
            <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                             totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                             onPageClicked={onPageClick}
                             onPrevPageClicked={onPrevPageClicked}
                             onNextPageClicked={onNextPageClicked}
                             isLoading={isSearching}
            />
          }
        </div>
      </div>

    </TabContentPane>
  )
}

const CustomerProfileRow = ({
                              serviceProfile, index,
                              currentPage, pageSize,
                              onRowClicked,
                            }) => {
  const [targetableLocations, setTargetableLocations] = useState([]);

  useEffect(() => {
    if (!serviceProfile || serviceProfile === null) {
      return;
    }
    setTargetableLocations(serviceProfile['locations'].slice());
  }, [serviceProfile]);

  const getLocationsCount = () => {
    if (!targetableLocations || targetableLocations === null) {
      return 0;
    }
    return targetableLocations.length;
  }

  return (
    <tr>
      <td className={"index-col"}>{getListRowSerial(pageSize, currentPage, index)}</td>
      <td className={"primary-col"}>
        <a href={"#"} role={"button"} className={"record-link"}
          onClick={(ev) => onRowClicked(ev, serviceProfile)}>
          {serviceProfile['customerName']}
        </a>
      </td>
      <td className={"secondary-col"}>
        <NullBlankField record={serviceProfile} fieldName={'companyName'} />
      </td>
      <td className={"secondary-col"}>
        {serviceProfile['businessTypeName']}
      </td>
      <td style={{ color: getLocationsCount() > 0 ? 'black' : 'gray' }}>
        {getLocationsCount()}
      </td>
    </tr>
  )
}
