import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useRef } from "react";

import { useAuth } from "../auth/AuthProvider";
import { useTabsPanel } from "../shared/TabsPanelContext";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { reportTypes } from "../../repos/constants";
import { viewSettings } from "../../repos/viewContexts";
import { authService } from "../../repos/apiServices";
import { refServices } from "../../repos/apiServices2";
import { inventoryServices } from "../../repos/apiServices";
import { inventoryServices as inventoryServices2 } from "../../repos/apiServices2";
import { technicalServices } from "../../repos/apiServices2";
import { reportServices } from "../../repos/apiServices2";
import { getListRowSerial } from "../../repos/utilities";
import { sanitizeIntValue } from "../../repos/utilities";
import { getRegionDisplayByName } from "../../repos/utilities";
import { reportStatus } from "../reports/Reports";
import { validateReportDateRanges } from "../reports/Reports";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { getFollowUpStageActionDisplay } from "../../repos/utilities";
import { formatNumber } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { InfoMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { TablePaginationLocal } from "../shared/TablePaginationLocal";
import { SortIndicator } from "../shared/SortIndicator";
import { ServiceLocationOptionsField } from "../reports/ServiceLocationOptionsField";
import { CommissionTypeOptionsField } from "../reports/CommissionTypeOptionsField";
import { BusinessTypeOptionsField } from "../reports/BusinessTypeOptionsField";
import { EmployeeOptionsField } from "./EmployeeOptionsField";
import { composeSummaryResult } from "./EmployeeOptionsField";
import { NullBlankValue } from "../service/NullBlankField";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "../reports/ReportEntryPage.css";
import "./SalesPersonSummariesReportPage.css";

const reportStatuses = reportStatus;


export const SalesPersonSummariesReportPage = () => {
  //#region States
  const [title, setTitle] = useState("New Report");
  const [displayTitle, setDisplayTitle] = useState(title);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(reportStatuses.draft);
  const [primaryUserId, setPrimaryUserId] = useState(null);
  const [isLocked, setIsLocked] = useState(false);

  const [reportId, setReportId] = useState(null);   // Not null in edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const titleRef = useRef();

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _reportId = null;
    if (state !== null) {
      _reportId = state['reportId'];
    }
    // TODO(yemon): refreshing the page in "edit" mode kinda clear the nav 'state' for some reason,
    // and thus resetting the entire page mode to 'new' mode

    if (_reportId !== null) {
      // edit mode
      fetchAndGenerateReport(_reportId);
    }
    else {
      // new mode
      titleRef.current.select();
      fetchReportReferences();
    }
  }, []);

  const fetchReportReferences = () => {
    setIsLoading(true);
    reportServices.fetchEmployeeBranchGroups()
      .then((response) => {
        const _references = response['data']
        prepareReportReferences(_references);
      })
      .catch((error) => {
        console.error(error);
        navigate(routes.salesReports_SalesPeronSummaries.url);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareReportReferences = (references) => {
    const employees = references['employees'];
    const employeeBranchGroups = references['employeeBranchGroups'];
    const employeeRanks = references['employeeRanks'];
    setEmployeesList(employees);
    setEmployeeBranchGroups(employeeBranchGroups);
    setEmployeeRanks(employeeRanks);
  }

  const fetchAndGenerateReport = (reportId) => {
    setIsLoading(true);
    reportServices.salesPersonSummaries.fetch(reportId)
      .then((response) => {
        const _report = response['data'];
        prepareReportReferences(_report['references']);
        prepareReportDetails(_report);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          if (errorResponse.status === 400 || errorResponse.status === 404) {
            navigate(routes.salesReports_SalesPeronSummaries.url);
          }
          if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unknown server error occurred when retrieving the report. Please contact the administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareReportDetails = (report) => {
    if (!report) {
      return;
    }

    {
      const details = report['report'];
      const options = report['options'];

      setReportId(details['id']);
      setTitle(details['title']);
      setDescription(details['description']);
      setIsLocked(details['isLocked']);

      const _fromDate = new Date(options['dateFrom']);
      setDateFrom(_fromDate);
      const _toDate = new Date(options['dateTo']);
      setDateTo(_toDate);

      const _employeeOptions = options['employees'];
      if (_employeeOptions.length === 0) {
        setEmployeeOptions([]);
      }
      else {
        const _summaryResults = [];
        for (let _employee of _employeeOptions) {
          _summaryResults.push(
            {
              'id': _employee['id'],
              'fullName': _employee['fullName'],
            }
          );
        }
        setEmployeeOptions(_summaryResults);

        {
          // NOTE(yemon): this is a 'mirrored' version of `prepareReportOptions(..)` function
          // I just don't trust the JS async timelines to have properly updated the 
          // respective states, in order to reliably call that function to prepare the exact
          // same payload.
          const _summaryInputs = [];
          for (let _employee of _summaryResults) {
            _summaryInputs.push({
              'employeeId': _employee['id'],
            });
          }

          const _reportOptions = {
            'dateFrom': _fromDate,
            'dateTo': _toDate,
            'summaryInputs': _summaryInputs,
          };
          generateSummaryReport(_reportOptions, true);
        }
      }
    }
  }

  useEffect(() => {
    if (title.trim() === "") {
      setDisplayTitle("(Untitled)");
      return;
    }
    setDisplayTitle(title);
  }, [title]);
  //#endregion

  //#region Control handlers
  const onReportsListClicked = (ev) => {
    navigate(routes.salesReports_SalesPeronSummaries.url);
  }

  const onDateFromChanged = (fromDate) => {
    setDateFrom(fromDate);
  }

  const onDateToChanged = (toDate) => {
    setDateTo(toDate);
  }

  const resetFieldValidations = () => {
    setFormErrors({
      title: [],
      dateFrom: [],
      dateTo: [],
    });
  }

  const isFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      title: [],
      dateFrom: [],
      dateTo: [],
    };
    if (!title || title.trim() === '') {
      _hasErrors = true;
      _formErrors.title = ["Report title is required."];
    }

    let dateValidation = validateReportDateRanges(dateFrom, dateTo);
    if (dateValidation.hasErrors) {
      _hasErrors = true;
      _formErrors.dateFrom = [dateValidation.fromDateError];
      _formErrors.dateTo = [dateValidation.toDateError];
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareReportPayload = () => {
    let desc = description !== null ? description.trim() : null;
    return {
      report: {
        'id': reportId === null ? null : reportId,
        'title': title.trim(),
        'description': desc,
        'reportType': reportTypes.sales.salesPersonSummaries,
        'userId': auth.getUserId()['eid'],
      },
      options: prepareReportOptions(),
    }
  }

  const prepareReportOptions = () => {
    const _summaryInputs = [];
    if (employeeOptions.length > 0) {
      for (let _employee of employeeOptions) {
        _summaryInputs.push({
          'employeeId': _employee['id'],
        });
      }
    }
    return {
      'dateFrom': dateFrom,
      'dateTo': dateTo,
      'summaryInputs': _summaryInputs,
    }
  }

  const onSaveClicked = (ev) => {
    if (!isFieldsValid()) {
      setHasErrors(true);
      return;
    }
    else {
      setHasErrors(false);
    }

    setIsAlertVisible(false);

    let payload = {
      ...prepareReportPayload(),
    }
    setIsSaving(true);
    reportServices.salesPersonSummaries.submit(payload)
      .then((response) => {
        let responseData = response['data'];
        setReportId(responseData['id']);

        setAlertType(alertTypes.info);
        setAlertMessage("Report successfully saved.")
        setIsAlertVisible(true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          handleReportApiErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const handleReportApiErrors = (errorResponse) => {
    if (!errorResponse) {
      return;
    }

    const responseData = errorResponse.data;
    if (errorResponse.status === 400 || errorResponse.status === 403) {
      setAlertType(alertTypes.error);
      setAlertMessage(responseData['message']);
      setIsAlertVisible(true);
    }
    if (errorResponse.status === 500) {
      setAlertType(alertTypes.error);
      setAlertMessage("Unknown server error occurred. Please contact the administrator.");
      setIsAlertVisible(true);
    }
  }

  const onCustomerRowClicked = (ev, profile) => {
    setTimeout(() => {
      navigate(routes.salesReports.url, {
        state: {
          'customerId': profile['customerId'],
          'serviceProfileId': profile['profileId'],
        }
      });
    }, 200);
  }

  const onGeneratorRowClicked = (ev, profile, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['generatorId'],
          'profileId': profile['profileId'],
          'customerId': profile['customerId'],
        }
      });
    }, 200);
  }
  //#endregion

  //#region Control handlers; Generate report
  const onGenerateClicked = (ev) => {
    generateSummaryReport(prepareReportOptions());
  }

  const generateSummaryReport = (reportOptions, isOnFetch=false) => {
    if (!isOnFetch) {
      if (!isFieldsValid()) {
        setHasErrors(true);
        return;
      }
      else {
        setHasErrors(false);
      }
    }

    const payload = {
      'uid': auth.getUserId(),
      'options': reportOptions,
    };

    setIsAlertVisible(false);
    setIsGeneratingSummary(true);
    reportServices.salesPersonSummaries.generate(payload)
      .then((response) => {
        const responseData = response['data'];
        const summaries = responseData['summaries'];

        setSummariesResponse(summaries);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          handleReportApiErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsGeneratingSummary(false);
      });
  }
  //#endregion

  //#region "Engineers" (employees) field options picker, and the table inputs model tracker 
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);
  const [employeeRanks, setEmployeeRanks] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const renderEmployeeOptionsField = () => {
    return (
      <EmployeeOptionsField employeeBranchGroups={employeeBranchGroups} employeeRanks={employeeRanks}
                            selectedOptions={employeeOptions} setSelectedOptions={setEmployeeOptions}
                            disabled={isFormControlsDisabled()} />
    )
  }

  useEffect(() => {
    setSummaryResults(employeeOptions);
  }, [employeeOptions]);
  //#endregion

  //#region Report results (both Summary and Details)

  // NOTE(yemon): `summaryResults` should not be bound directly from the generate response
  // like all the other reports. It has to be only localized display source, of which the 
  // response from generate API call (`employeeOptions`) will sync in and populate with.
  const [summariesResponse, setSummariesResponse] = useState([]);
  const [summaryResults, setSummaryResults] = useState([]);
  const [allSalesInquiriesTotal, setAllSalesInquiriesTotal] = useState(0);
  const [allQuotationRequestsTotal, setAllQuotationRequestsTotal] = useState(0);
  const [allOrderConfirmationsTotal, setAllOrderConfirmationsTotal] = useState(0);

  useEffect(() => {
    syncSummariesResponseWithSummaryResults(summariesResponse.slice());
  }, [summariesResponse]);

  const syncSummariesResponseWithSummaryResults = (summariesResponse) => {
    let _summaryResults = summaryResults.slice();
    if (_summaryResults.length === 0) {
      return;
    }

    let _allSalesInquiries = 0;
    let _allQuotationRequests = 0;
    let _allOrderConfirmations = 0;

    for (let result of _summaryResults) {
      const summary = summariesResponse.find((summary) => summary['employeeId'] === result['id']);
      if (summary === undefined) {
        continue;
      }
      if (summary['nil'] === true) {
        continue;
      }

      result['leads'] = summary['leads'];
      result['prospects'] = summary['prospects'];
      result['quotations'] = summary['quotations'];
      result['deals'] = summary['deals'];
      result['wins'] = summary['wins'];
      result['losts'] = summary['losts'];
      result['totalSalesInquiries'] = summary['totalSalesInquiries'];
      _allSalesInquiries += summary['totalSalesInquiries'];

      result['totalQuotationRequests'] = summary['totalQuotationRequests'];
      _allQuotationRequests += summary['totalQuotationRequests'];
      result['totalOrderConfirmations'] = summary['totalOrderConfirmations'];;
      _allOrderConfirmations += summary['totalOrderConfirmations'];
    }
    setSummaryResults(_summaryResults);
    setAllSalesInquiriesTotal(_allSalesInquiries);
    setAllQuotationRequestsTotal(_allQuotationRequests);
    setAllOrderConfirmationsTotal(_allOrderConfirmations);
  }
  //#endregion

  //#region Utilities
  const isFormControlsDisabled = () => {
    return isLoading || isSaving || isGeneratingSummary;
  }

  const isAllowDelete = () => {
    return !isLocked;
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesReports.displayShort} anchorTo={routes.salesReports.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onReportsListClicked}>
                  {routes.salesReports_SalesPeronSummaries.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={displayTitle} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{displayTitle}</h1>
          </div>

          <div className={"form-section"}>
            <div className={"entry-form"}>
              <div className={"form-message"}>
                {!reportId &&
                  <WarningMessage>
                    The report has <b>not been saved</b> yet. Make sure to save the report in order to reuse the same criteria and results easily.
                  </WarningMessage>
                }
              </div>
            </div>
            <div className={"entry-form report-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"title"}>
                  Title:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"description"}>
                  Description:
                </label>
              </div>

              <div className={"form-input"}>
                <input type={"text"} id={"title"} className={"form-control md-field"} autoComplete={"off"}
                       autoFocus={true} ref={titleRef}
                       disabled={isFormControlsDisabled()} maxLength={50}
                       value={title} onChange={(ev) => setTitle(ev.target.value)} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['title']} />
              </div>

              <div className={"form-input"}>
                <textarea id={"description"} className={"form-control"} rows={3} disabled={isFormControlsDisabled()}
                          value={description} onChange={(ev) => setDescription(ev.target.value)} maxLength={100}>
                </textarea>
              </div>
            </div>
          </div>


          <div className={"form-section"}>
            <h2>Report Options</h2>

            <div className={"entry-form report-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"dateFrom"}>
                  From Date:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"businessType"}>
                  Engineer(s):
                </label>
              </div>

              <div className={"form-input"}>
                <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                            placeholderText={"Report date from."} required={false} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateFrom} onChange={onDateFromChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateFrom']} />
              </div>

              <div className={"form-input"}>
                {renderEmployeeOptionsField()}
                <FieldErrorMessage visible={hasErrors} message={formErrors['employeeOptions']} />
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"dateTo"}>
                  To Date:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-input-r"}>
                <DatePicker id={"dateTo"} className={getDateClassName(true)}
                            placeholderText={"Report date to."} required={false} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateTo} onChange={onDateToChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateTo']} />
              </div>
            </div>
          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary right-margin"} disabled={isFormControlsDisabled()}
                      onClick={onSaveClicked}>
                {isSaving && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSaving && <i className={"fa-solid fa-check"}></i>}
                Save
              </button>
              <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled() || isLocked}
                      onClick={onGenerateClicked}>
                {isGeneratingSummary && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isGeneratingSummary && <i className={`fa-solid ${routes.serviceReports.faIcon}`}></i>}
                Generate
              </button>
            </div>

            <div className={"right-side right-aligned"}>
            </div>
          </div>

          <ReportResultsTable isLoading={isLoading} isGenerating={isGeneratingSummary}
                              summaryResults={summaryResults} 
                              allSalesInquiriesTotal={allSalesInquiriesTotal} 
                              allQuotationRequestsTotal={allQuotationRequestsTotal} 
                              allOrderConfirmationsTotal={allOrderConfirmationsTotal} />

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>

      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const ReportResultsTable = ({ 
                              isLoading, isGenerating, 
                              summaryResults, allSalesInquiriesTotal, allQuotationRequestsTotal, allOrderConfirmationsTotal,
                            }) =>{
  const alphabetically = (emp1, emp2) => {
    return emp1['fullName'].localeCompare(emp2['fullName']);
  }

  return (
    <div className={"form-section table-section"}>
      <div className={"data-table report-results-table"}>
        <table>
          <thead>
          <tr>
            <th scope={"col"} colSpan={2}></th>

            <th scope={"col"} colSpan={7} className={"group-heading group-left"}>
              Sales Inquiries
            </th>

            <th scope={"col"} className={"total-heading group-left"}>
            </th>

            <th scope={"col"} className={"total-heading group-left"}>
            </th>
          </tr>
          <tr>
            <th scope={"col"} className={"index-col-head"}>#</th>
            <th scope={"col"} className={"title-col"}>
              Sales Person
              <SortIndicator isAscending={true} />
            </th>

            <th scope={"col"} className={"group-left right-aligned"}>Lead</th>
            <th scope={"col"} className={"right-aligned"}>Prospect</th>
            <th scope={"col"} className={"right-aligned"}>Quotation</th>
            <th scope={"col"} className={"right-aligned"}>Deal</th>
            <th scope={"col"} className={"right-aligned"}>Wins</th>
            <th scope={"col"} className={"right-aligned"}>Lost</th>
            <th scope={"col"} className={"right-aligned"}>Total</th>

            <th scope={"col"} className={"group-left right-aligned"}>
              Qutoation Requests
            </th>

            <th scope={"col"} className={"group-left right-aligned"}>
              Order Confirmations
            </th>
          </tr>
          </thead>
          <tbody>
          {isLoading && <TableLoadingIndicator colspan={11} />}

          {!isLoading && summaryResults && summaryResults.length > 0 &&
            summaryResults.sort(alphabetically).map((summary, index) =>
              <tr key={summary['id']}>
                <td className={"index-col"}>{index + 1}</td>
                <td>
                  {summary['fullName']}
                </td>

                <td className={"group-left right-aligned"}>
                  {renderNullZeroNumber(summary['leads'])}
                </td>
                <td className={"right-aligned"}>
                  {renderNullZeroNumber(summary['prospects'])}
                </td>
                <td className={"right-aligned"}>
                  {renderNullZeroNumber(summary['quotations'])}
                </td>
                <td className={"right-aligned"}>
                  {renderNullZeroNumber(summary['deals'])}
                </td>
                <td className={"right-aligned"}>
                  {renderNullZeroNumber(summary['wins'])}
                </td>
                <td className={"right-aligned"}>
                  {renderNullZeroNumber(summary['losts'])}
                </td>
                <td className={"right-aligned bolden"}>
                  {renderNullZeroNumber(summary['totalSalesInquiries'])}
                </td>

                <td className={"right-aligned bolden group-left"}>
                  {renderNullZeroNumber(summary['totalQuotationRequests'])}
                </td>

                <td className={"right-aligned bolden group-left"}>
                  {renderNullZeroNumber(summary['totalOrderConfirmations'])}
                </td>
              </tr>
            )
          }

          {!isLoading && summaryResults && summaryResults.length > 0 && 
            <tr className={"highlight"}>
              <td colSpan={2} className={"right-aligned group-right"}>
                Total:
              </td>
              <td colSpan={7} className={"right-aligned group-right"}>
                {formatNumber(allSalesInquiriesTotal, 0)}
              </td>

              <td className={"right-aligned group-right"}>
                {formatNumber(allQuotationRequestsTotal, 0)}
              </td>

              <td className={"right-aligned group-right"}>
                {formatNumber(allOrderConfirmationsTotal, 0)}
              </td>
            </tr>
          }

          {!isLoading && (!summaryResults || summaryResults.length === 0) &&
            <TableEmptyRow colSpan={11} />
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const salesSources = refs.sales.source;
const salesContactForms = refs.sales.contactForm;

const renderNullZeroNumber = (value) => {
  if (value === undefined || value === null) return '-';
  if (value === 0) return '-';
  return formatNumber(value, 0);
}
