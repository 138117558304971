import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useRef } from "react";

import { useAuth } from "../auth/AuthProvider";
import { useTabsPanel } from "../shared/TabsPanelContext";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { reportTypes } from "../../repos/constants";
import { viewSettings } from "../../repos/viewContexts";
import { authService } from "../../repos/apiServices";
import { refServices } from "../../repos/apiServices2";
import { inventoryServices } from "../../repos/apiServices";
import { inventoryServices as inventoryServices2 } from "../../repos/apiServices2";
import { technicalServices } from "../../repos/apiServices2";
import { reportServices } from "../../repos/apiServices2";
import { getListRowSerial } from "../../repos/utilities";
import { sanitizeIntValue } from "../../repos/utilities";
import { getRegionDisplayByName } from "../../repos/utilities";
import { reportStatus } from "../reports/Reports";
import { validateReportDateRanges } from "../reports/Reports";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { getFollowUpStageActionDisplay } from "../../repos/utilities";
import { formatNumber } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { InfoMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { TablePaginationLocal } from "../shared/TablePaginationLocal";
import { SortIndicator } from "../shared/SortIndicator";
import { ServiceLocationOptionsField } from "../reports/ServiceLocationOptionsField";
import { CommissionTypeOptionsField } from "../reports/CommissionTypeOptionsField";
import { BusinessTypeOptionsField } from "../reports/BusinessTypeOptionsField";
import { EmployeeOptionsField } from "./EmployeeOptionsField";
import { composeSummaryResult } from "./EmployeeOptionsField";
import { NullBlankValue } from "../service/NullBlankField";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "../reports/ReportEntryPage.css";
import "./InquiryRecordsReportPage.css";

const reportStatuses = reportStatus;
const commissionTypes = refs.inventory.serviceGeneratorCommissionType;
const summaryTab = {
  tab: "summary-tab",
  content: "summary-content",
  label: "Summary",
};
const detailsTab = {
  tab: "details-tab",
  content: "details-content",
  label: "Details",
};


export const InquiryRecordsReportPage = () => {
  //#region States
  const [title, setTitle] = useState("New Report");
  const [displayTitle, setDisplayTitle] = useState(title);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(reportStatuses.draft);
  const [primaryUserId, setPrimaryUserId] = useState(null);
  const [isLocked, setIsLocked] = useState(false);

  const [reportId, setReportId] = useState(null);   // Not null in edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const titleRef = useRef();

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _reportId = null;
    if (state !== null) {
      _reportId = state['reportId'];
    }
    // TODO(yemon): refreshing the page in "edit" mode kinda clear the nav 'state' for some reason,
    // and thus resetting the entire page mode to 'new' mode

    if (_reportId !== null) {
      // edit mode
      fetchAndGenerateReport(_reportId);
    }
    else {
      // new mode
      titleRef.current.select();
      fetchReportReferences();
    }
  }, []);

  const fetchReportReferences = () => {
    setIsLoading(true);
    reportServices.fetchEmployeeBranchGroups()
      .then((response) => {
        const _references = response['data']
        prepareReportReferences(_references);
      })
      .catch((error) => {
        console.error(error);
        navigate(routes.salesReports_InquiryRecords.url);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareReportReferences = (references) => {
    const employees = references['employees'];
    const employeeBranchGroups = references['employeeBranchGroups'];
    const employeeRanks = references['employeeRanks'];
    setEmployeesList(employees);
    setEmployeeBranchGroups(employeeBranchGroups);
    setEmployeeRanks(employeeRanks);
  }

  const fetchAndGenerateReport = (reportId) => {
    setIsLoading(true);
    reportServices.inquiryRecords.fetch(reportId)
      .then((response) => {
        const _report = response['data'];
        prepareReportReferences(_report['references']);
        prepareReportDetails(_report);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          if (errorResponse.status === 400 || errorResponse.status === 404) {
            navigate(routes.salesReports_InquiryRecords.url);
          }
          if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unknown server error occurred when retrieving the report. Please contact the administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareReportDetails = (report) => {
    if (!report) {
      return;
    }

    {
      const details = report['report'];
      const options = report['options'];

      setReportId(details['id']);
      setTitle(details['title']);
      setDescription(details['description']);
      setIsLocked(details['isLocked']);

      const _fromDate = new Date(options['dateFrom']);
      setDateFrom(_fromDate);
      const _toDate = new Date(options['dateTo']);
      setDateTo(_toDate);

      const _employeeOptions = options['employees'];
      if (_employeeOptions.length === 0) {
        setEmployeeOptions([]);
      }
      else {
        const _summaryResults = [];
        for (let _employee of _employeeOptions) {
          _summaryResults.push(
            composeSummaryResult(
              _employee['id'], 
              _employee['fullName'], 
              _employee['targetCall'], 
              _employee['targetVisit']
            )
          );
        }
        setEmployeeOptions(_summaryResults);

        {
          // NOTE(yemon): this is a 'mirrored' version of `prepareReportOptions(..)` function
          // I just don't trust the JS async timelines to have properly updated the 
          // respective states, in order to reliably call that function to prepare the exact
          // same payload.
          const _summaryInputs = [];
          for (let _employee of _summaryResults) {
            _summaryInputs.push({
              'employeeId': _employee['id'],
              'targetCall': _employee['targetCall'],
              'targetVisit': _employee['targetVisit'],
            });
          }

          const _reportOptions = {
            'dateFrom': _fromDate,
            'dateTo': _toDate,
            'summaryInputs': _summaryInputs,
          };
          generateSummaryReport(_reportOptions, true);
        }
      }
    }
  }

  useEffect(() => {
    if (title.trim() === "") {
      setDisplayTitle("(Untitled)");
      return;
    }
    setDisplayTitle(title);
  }, [title]);
  //#endregion

  //#region Control handlers
  const onReportsListClicked = (ev) => {
    navigate(routes.salesReports_InquiryRecords.url);
  }

  const onDateFromChanged = (fromDate) => {
    setDateFrom(fromDate);
  }

  const onDateToChanged = (toDate) => {
    setDateTo(toDate);
  }

  const resetFieldValidations = () => {
    setFormErrors({
      title: [],
      dateFrom: [],
      dateTo: [],
    });
  }

  const isFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      title: [],
      dateFrom: [],
      dateTo: [],
    };
    if (!title || title.trim() === '') {
      _hasErrors = true;
      _formErrors.title = ["Report title is required."];
    }

    let dateValidation = validateReportDateRanges(dateFrom, dateTo);
    if (dateValidation.hasErrors) {
      _hasErrors = true;
      _formErrors.dateFrom = [dateValidation.fromDateError];
      _formErrors.dateTo = [dateValidation.toDateError];
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareReportPayload = () => {
    let desc = description !== null ? description.trim() : null;
    return {
      report: {
        'id': reportId === null ? null : reportId,
        'title': title.trim(),
        'description': desc,
        'reportType': reportTypes.sales.inquiryRecords,
        'userId': auth.getUserId()['eid'],
      },
      options: prepareReportOptions(),
    }
  }

  const prepareReportOptions = () => {
    const _summaryInputs = [];
    if (employeeOptions.length > 0) {
      for (let _employee of employeeOptions) {
        _summaryInputs.push({
          'employeeId': _employee['id'],
          'targetCall': _employee['targetCall'],
          'targetVisit': _employee['targetVisit'],
        });
      }
    }
    return {
      'dateFrom': dateFrom,
      'dateTo': dateTo,
      'summaryInputs': _summaryInputs,
    }
  }

  const onSaveClicked = (ev) => {
    if (!isFieldsValid()) {
      setHasErrors(true);
      return;
    }
    else {
      setHasErrors(false);
    }

    setIsAlertVisible(false);

    let payload = {
      ...prepareReportPayload(),
    }
    setIsSaving(true);
    reportServices.inquiryRecords.submit(payload)
      .then((response) => {
        let responseData = response['data'];
        setReportId(responseData['id']);

        setAlertType(alertTypes.info);
        setAlertMessage("Report successfully saved.")
        setIsAlertVisible(true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          handleReportApiErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const handleReportApiErrors = (errorResponse) => {
    if (!errorResponse) {
      return;
    }

    const responseData = errorResponse.data;
    if (errorResponse.status === 400 || errorResponse.status === 403) {
      setAlertType(alertTypes.error);
      setAlertMessage(responseData['message']);
      setIsAlertVisible(true);
    }
    if (errorResponse.status === 500) {
      setAlertType(alertTypes.error);
      setAlertMessage("Unknown server error occurred. Please contact the administrator.");
      setIsAlertVisible(true);
    }
  }

  const onCustomerRowClicked = (ev, profile) => {
    setTimeout(() => {
      navigate(routes.salesReports.url, {
        state: {
          'customerId': profile['customerId'],
          'serviceProfileId': profile['profileId'],
        }
      });
    }, 200);
  }

  const onGeneratorRowClicked = (ev, profile, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['generatorId'],
          'profileId': profile['profileId'],
          'customerId': profile['customerId'],
        }
      });
    }, 200);
  }
  //#endregion

  //#region Control handlers; Generate report
  const onGenerateClicked = (ev) => {
    generateSummaryReport(prepareReportOptions());
  }

  const generateSummaryReport = (reportOptions, isOnFetch=false) => {
    if (!isOnFetch) {
      if (!isFieldsValid()) {
        setHasErrors(true);
        return;
      }
      else {
        setHasErrors(false);
      }
    }

    const payload = {
      'uid': auth.getUserId(),
      'options': reportOptions,
    };

    setIsAlertVisible(false);
    setIsGeneratingSummary(true);
    reportServices.inquiryRecords.generateSummary(payload)
      .then((response) => {
        const responseData = response['data'];
        const summaries = responseData['summaries'];
        const details = responseData['details'];

        setSummariesResponse(summaries);
        setDetailsResponse(details);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          handleReportApiErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsGeneratingSummary(false);
      });
  }
  //#endregion

  //#region "Engineers" (employees) field options picker, and the table inputs model tracker 
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);
  const [employeeRanks, setEmployeeRanks] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const renderEmployeeOptionsField = () => {
    return (
      <EmployeeOptionsField employeeBranchGroups={employeeBranchGroups} employeeRanks={employeeRanks}
                            selectedOptions={employeeOptions} setSelectedOptions={setEmployeeOptions}
                            disabled={isFormControlsDisabled()} />
    )
  }

  useEffect(() => {
    // NOTE(yemon): Right now, updating any Summary tab row inputs (which is reflected into the 
    // `employeeOptions` state), will reset the Details tab Employee dropdown filters as well.
    // Which might be kinda annoying, depending on who you ask. Just gonna leave it 
    // as it is for the time being.
    setSelectedSalesPersonId(null);
    setFilteredDetailResults(detailsResponse.slice());
    setSummaryResults(employeeOptions);
  }, [employeeOptions]);

  const setInputTargetCallOf = (employeeId, newValue) => {
    const _summaryInputs = employeeOptions.slice();
    for (let _emp of _summaryInputs) {
      const _newValue = newValue && newValue !== null ? newValue.trim() : "0";
      if (_emp['id'] === employeeId && _newValue !== "") {
        let _targetCall = sanitizeIntValue(_newValue);
        if (_targetCall < 0) {
          _targetCall = Math.abs(_targetCall);
        }
        _emp['targetCall'] = _targetCall;
        if (_emp['totalCalls'] > 0) {
          _emp['callPercent'] = calculatePercentage(_emp['totalCalls'], _targetCall);
        } else {
          _emp['callPercent'] = 0;
        }
        break;
      }
    }
    setEmployeeOptions(_summaryInputs);
  }

  const setInputTargetVisitOf = (employeeId, newValue) => {
    const _summaryInputs = employeeOptions.slice();
    for (let _emp of _summaryInputs) {
      const _newValue = newValue && newValue !== null ? newValue.trim() : "0";
      if (_emp['id'] === employeeId && _newValue !== "") {
        let _targetVisit = sanitizeIntValue(_newValue);
        if (_targetVisit < 0) {
          _targetVisit = Math.abs(_targetVisit);
        }
        _emp['targetVisit'] = _targetVisit;
        if (_emp['totalVisits'] > 0) {
          _emp['visitPercent'] = calculatePercentage(_emp['totalVisits'], _targetVisit);
        } else {
          _emp['visitPercent'] = 0;
        }
        break;
      }
    }
    setEmployeeOptions(_summaryInputs);
  }
  //#endregion

  //#region Report results (both Summary and Details)

  // NOTE(yemon): `summaryResults` should not be bound directly from the generate response
  // like all the other reports. It has to be only localized display source, of which the 
  // response from generate API call (`employeeOptions`) will sync in and populate with.
  const [summariesResponse, setSummariesResponse] = useState([]);
  const [summaryResults, setSummaryResults] = useState([]);
  const [allTotalCalls, setAllTotalCalls] = useState(0);
  const [allTotalVisits, setAllTotalVisits] = useState(0);
  const [allGrandTotals, setAllGrandTotals] = useState(0);

  useEffect(() => {
    syncSummariesResponseWithSummaryResults(summariesResponse.slice());
  }, [summariesResponse]);

  const [detailsResponse, setDetailsResponse] = useState([]);
  const [filteredDetailResults, setFilteredDetailResults] = useState([]);
  const [selectedSalesPersonId, setSelectedSalesPersonId] = useState(null);

  useEffect(() => {
    setSelectedSalesPersonId(null);
    setFilteredDetailResults(detailsResponse.slice());
  }, [detailsResponse]);

  useEffect(() => {
    if (selectedSalesPersonId === null) {
      setFilteredDetailResults(detailsResponse.slice());
    }
    else {
      const _filtered = detailsResponse.filter((history) => {
        return history['followUpReporterId'] === selectedSalesPersonId;
      }).slice();
      setFilteredDetailResults(_filtered);
    }
  }, [selectedSalesPersonId]);

  const syncSummariesResponseWithSummaryResults = (summariesResponse) => {
    let _summaryResults = summaryResults.slice();
    if (_summaryResults.length === 0) {
      return;
    }

    let _allTotalCalls = 0;
    let _allTotalVisits = 0;
    let _allGrandTotals = 0;

    for (let result of _summaryResults) {
      const summary = summariesResponse.find((summary) => summary['employeeId'] === result['id']);
      if (summary === undefined) {
        continue;
      }
      if (summary['nil'] === true) {
        continue;
      }

      result['callLeads'] = summary['callLeads'];
      result['callProspects'] = summary['callProspects'];
      result['callQuotations'] = summary['callQuotations'];
      result['callDeals'] = summary['callDeals'];
      result['callWins'] = summary['callWins'];
      result['callLosts'] = summary['callLosts'];
      const _totalCalls = summary['totalCalls'];
      result['totalCalls'] = _totalCalls;
      if (_totalCalls !== null) {
        if (_totalCalls === 0) {
          result['callPercent'] = 0;
        } else {
          result['callPercent'] = calculatePercentage(_totalCalls, result['targetCall']);
          _allTotalCalls += _totalCalls;
        }
      }

      result['visitLeads'] = summary['visitLeads'];
      result['visitProspects'] = summary['visitProspects'];
      result['visitQuotations'] = summary['visitQuotations'];
      result['visitDeals'] = summary['visitDeals'];
      result['visitWins'] = summary['visitWins'];
      result['visitLosts'] = summary['visitLosts'];
      const _totalVisits = summary['totalVisits'];
      result['totalVisits'] = _totalVisits;
      if (_totalVisits !== null) {
        if (_totalVisits === 0) {
          result['visitPercent'] = 0;
        } else {
          result['visitPercent'] = calculatePercentage(_totalVisits, result['targetVisit']);
        _allTotalVisits += _totalVisits;
        }
      }

      result['grandTotal'] = summary['grandTotal'];
      _allGrandTotals += summary['grandTotal'];
    }
    setSummaryResults(_summaryResults);
    setAllTotalCalls(_allTotalCalls);
    setAllTotalVisits(_allTotalVisits);
    setAllGrandTotals(_allGrandTotals);
  }

  const calculatePercentage = (count, target) => {
    if (count === null) {
      return 0;
    }
    if (count === 0 || target === 0) {
      return 0;
    }

    return Math.round((count * 100) / target);
  };
  //#endregion

  //#region Utilities
  const isFormControlsDisabled = () => {
    return isLoading || isSaving || isGeneratingSummary;
  }

  const isAllowDelete = () => {
    return !isLocked;
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesReports.displayShort} anchorTo={routes.salesReports.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onReportsListClicked}>
                  {routes.salesReports_InquiryRecords.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={displayTitle} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{displayTitle}</h1>
          </div>

          <div className={"form-section"}>
            <div className={"entry-form"}>
              <div className={"form-message"}>
                {!reportId &&
                  <WarningMessage>
                    The report has <b>not been saved</b> yet. Make sure to save the report in order to reuse the same criteria and results easily.
                  </WarningMessage>
                }
              </div>
            </div>
            <div className={"entry-form report-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"title"}>
                  Title:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"description"}>
                  Description:
                </label>
              </div>

              <div className={"form-input"}>
                <input type={"text"} id={"title"} className={"form-control md-field"} autoComplete={"off"}
                       autoFocus={true} ref={titleRef}
                       disabled={isFormControlsDisabled()} maxLength={50}
                       value={title} onChange={(ev) => setTitle(ev.target.value)} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['title']} />
              </div>

              <div className={"form-input"}>
                <textarea id={"description"} className={"form-control"} rows={3} disabled={isFormControlsDisabled()}
                          value={description} onChange={(ev) => setDescription(ev.target.value)} maxLength={100}>
                </textarea>
              </div>
            </div>
          </div>


          <div className={"form-section"}>
            <h2>Report Options</h2>

            <div className={"entry-form report-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"dateFrom"}>
                  From Date:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"businessType"}>
                  Engineer(s):
                </label>
              </div>

              <div className={"form-input"}>
                <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                            placeholderText={"Report date from."} required={false} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateFrom} onChange={onDateFromChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateFrom']} />
              </div>

              <div className={"form-input"}>
                {renderEmployeeOptionsField()}
                <FieldErrorMessage visible={hasErrors} message={formErrors['employeeOptions']} />
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"dateTo"}>
                  To Date:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-input-r"}>
                <DatePicker id={"dateTo"} className={getDateClassName(true)}
                            placeholderText={"Report date to."} required={false} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateTo} onChange={onDateToChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateTo']} />
              </div>
            </div>
          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary right-margin"} disabled={isFormControlsDisabled()}
                      onClick={onSaveClicked}>
                {isSaving && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSaving && <i className={"fa-solid fa-check"}></i>}
                Save
              </button>
              <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled() || isLocked}
                      onClick={onGenerateClicked}>
                {isGeneratingSummary && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isGeneratingSummary && <i className={`fa-solid ${routes.serviceReports.faIcon}`}></i>}
                Generate
              </button>
            </div>

            <div className={"right-side right-aligned"}>
            </div>
          </div>

          <TabsPanelProvider initialHeaderElementId={summaryTab.tab} 
                             initialContentPaneElementId={summaryTab.content}>
            <TabHeader>
              <TabHeaderItem itemElementId={summaryTab.tab} contentPaneElementId={summaryTab.content}
                             itemLabel={summaryTab.label} />
              <TabHeaderItem itemElementId={detailsTab.tab} contentPaneElementId={detailsTab.content}
                             itemLabel={detailsTab.label} />
            </TabHeader>
            <TabContent>
              <SummaryTabPane isLoading={isLoading} isGenerating={isGeneratingSummary}
                              setInputTargetCallOf={setInputTargetCallOf} 
                              setInputTargetVisitOf={setInputTargetVisitOf}
                              summaryResults={summaryResults} 
                              allTotalCalls={allTotalCalls} allTotalVisits={allTotalVisits} 
                              allGrandTotals={allGrandTotals} />
              <DetailsTabPane isLoading={isLoading} isGenerating={isGeneratingSummary}
                              salesPersonId={selectedSalesPersonId} setSalesPersonId={setSelectedSalesPersonId}
                              selectedEmployeeOptions={employeeOptions}
                              employeeBranchGroups={employeeBranchGroups} employeesList={employeesList} 
                              detailResults={filteredDetailResults} />
            </TabContent>
          </TabsPanelProvider>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>

      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const SummaryTabPane = ({ 
                          isLoading, isGenerating, 
                          setInputTargetCallOf, setInputTargetVisitOf,
                          summaryResults, allTotalCalls, allTotalVisits, allGrandTotals,
                        }) =>{
  const tabsPanel = useTabsPanel();

  const alphabetically = (emp1, emp2) => {
    return emp1['fullName'].localeCompare(emp2['fullName']);
  }

  const targetCallValueOf = (employeeId) => {
    if (summaryResults.length === 0) {
      return 0;
    }
    const input = summaryResults.find((emp) => emp['id'] === employeeId);
    if (!input) {
      return 0;
    }
    return input['targetCall'];
  }

  const targetVisitValueOf = (employeeId) => {
    if (summaryResults.length === 0) {
      return 0;
    }
    const input = summaryResults.find((emp) => emp['id'] === employeeId);
    if (!input) {
      return 0;
    }
    return input['targetVisit'];
  }

  return (
    <TabContentPane contentPaneElementId={summaryTab.content}>
      <div className={"form-section table-section"}>
        <div className={"data-table inquiry-summary-table"}>
          <table>
            <thead>
            <tr>
              <th scope={"col"} colSpan={2}></th>

              <th scope={"col"} className={"group-heading-small group-left right-aligned"}>
                Activities
              </th>

              <th scope={"col"} colSpan={7} className={"group-heading group-left"}>
                Call
              </th>

              <th scope={"col"} colSpan={7} className={"group-heading group-left"}>
                Visit
              </th>

              <th scope={"col"} colSpan={4} className={"fill-heading group-left"}></th>
            </tr>
            <tr>
              <th scope={"col"} className={"index-col-head"}>#</th>
              <th scope={"col"} className={"title-col"}>
                Sales Person
                <SortIndicator isAscending={true} />
              </th>

              <th scope={"col"} className={"group-left right-aligned"}>
                Total
              </th>

              <th scope={"col"} className={"group-left right-aligned"}>Lead</th>
              <th scope={"col"} className={"right-aligned"}>Prospect</th>
              <th scope={"col"} className={"right-aligned"}>Quotation</th>
              <th scope={"col"} className={"right-aligned"}>Deal</th>
              <th scope={"col"} className={"right-aligned"}>Wins</th>
              <th scope={"col"} className={"right-aligned"}>Lost</th>
              <th scope={"col"} className={"right-aligned"}>Total</th>

              <th scope={"col"} className={"group-left right-aligned"}>Lead</th>
              <th scope={"col"} className={"right-aligned"}>Prospect</th>
              <th scope={"col"} className={"right-aligned"}>Quotation</th>
              <th scope={"col"} className={"right-aligned"}>Deal</th>
              <th scope={"col"} className={"right-aligned"}>Wins</th>
              <th scope={"col"} className={"right-aligned"}>Lost</th>
              <th scope={"col"} className={"right-aligned"}>Total</th>

              <th scope={"col"} className={"group-left"}>Target Call</th>
              <th scope={"col"}>Target Visit</th>
              <th scope={"col"} className={"right-aligned"}>Call %</th>
              <th scope={"col"} className={"right-aligned"}>Visit %</th>
            </tr>
            </thead>
            <tbody>
            {isLoading && <TableLoadingIndicator colspan={21} />}

            {!isLoading && summaryResults && summaryResults.length > 0 &&
              summaryResults.sort(alphabetically).map((summary, index) =>
                <tr key={summary['id']}>
                  <td className={"index-col"}>{index + 1}</td>
                  <td>
                    {summary['fullName']}
                  </td>

                  <td className={"group-left right-aligned bolden"}>
                    {renderNullZeroNumber(summary['grandTotal'])}
                  </td>

                  <td className={"group-left right-aligned"}>
                    {renderNullZeroNumber(summary['callLeads'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['callProspects'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['callQuotations'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['callDeals'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['callWins'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['callLosts'])}
                  </td>
                  <td className={"right-aligned bolden"}>
                    {renderNullZeroNumber(summary['totalCalls'])}
                  </td>

                  <td className={"group-left right-aligned"}>
                    {renderNullZeroNumber(summary['visitLeads'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['visitProspects'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['visitQuotations'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['visitDeals'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['visitWins'])}
                  </td>
                  <td className={"right-aligned"}>
                    {renderNullZeroNumber(summary['visitLosts'])}
                  </td>
                  <td className={"right-aligned bolden"}>
                    {renderNullZeroNumber(summary['totalVisits'])}
                  </td>

                  <td className={"group-left right-aligned"}>
                    <input type={"text"} name={`target-call-${summary['id']}`} autoComplete={"off"}
                           className={"form-control md-field target-input"}
                           maxLength={3} disabled={isLoading || isGenerating}
                           value={targetCallValueOf(summary['id'])} 
                           onChange={(ev) => setInputTargetCallOf(summary['id'], ev.target.value)} />
                  </td>
                  <td className={"right-aligned"}>
                    <input type={"text"} name={`target-visit-${summary['id']}`} autoComplete={"off"}
                           className={"form-control md-field target-input"}
                           maxLength={3} disabled={isLoading || isGenerating}
                           value={targetVisitValueOf(summary['id'])} 
                           onChange={(ev) => setInputTargetVisitOf(summary['id'], ev.target.value)} />
                  </td>
                  <td className={"right-aligned bolden"}>
                    {formatNumber(summary['callPercent'], 0)}
                  </td>
                  <td className={"right-aligned bolden"}>
                    {formatNumber(summary['visitPercent'], 0)}
                  </td>
                </tr>
              )
            }

            {!isLoading && summaryResults && summaryResults.length > 0 && 
              <tr className={"highlight"}>
                <td colSpan={2} className={"right-aligned group-right"}>
                  Total:
                </td>
                <td className={"right-aligned group-right"}>
                  {formatNumber(allGrandTotals, 0)}
                </td>
                <td colSpan={7} className={"right-aligned group-right"}>
                  {formatNumber(allTotalCalls, 0)}
                </td>
                <td colSpan={7} className={"right-aligned group-right"}>
                  {formatNumber(allTotalVisits, 0)}
                </td>
                <td colSpan={4}></td>
              </tr>
            }

            {!isLoading && (!summaryResults || summaryResults.length === 0) &&
              <TableEmptyRow colSpan={21} />
            }
            </tbody>
          </table>
        </div>
      </div>
    </TabContentPane>
  )
}

const salesSources = refs.sales.source;
const salesContactForms = refs.sales.contactForm;

const DetailsTabPane = ({
                          isLoading, isGenerating, 
                          salesPersonId, setSalesPersonId, selectedEmployeeOptions,
                          employeesList, detailResults,
                        }) => {
  const [pageData, setPageData] = useState([]);
  const [currentPage, setPage] = useState(1);

  const tabsPanel = useTabsPanel();

  const isLoadingDetails = () => {
    return isLoading || isGenerating;
  }

  const onlySelectedEmployees = (employee) => {
    return selectedEmployeeOptions.some((_employee) => _employee['id'] === employee['id']);
  }

  const alphabetically = (emp1, emp2) => {
    return emp1['fullName'].localeCompare(emp2['fullName']);
  }

  const onSalesPersonChanged = (ev) => {
    const empId = ev.target.value;
    if (empId === "-1") {
      setSalesPersonId(null);
      return;
    }
    setSalesPersonId(empId);
  }

  return (
    <TabContentPane contentPaneElementId={detailsTab.content}>
      <div className={"form-section"}>
        <div className={"entry-form report-entry-form"}>
          <div className={"form-label"}>
            <label htmlFor={"salesPersons"}>
              Sales Person:
            </label>
          </div>

          <div className={"form-input"}>
            <select name={"salesPersons"} id={"salesPersons"} className={"form-control form-select"}
                    disabled={isLoadingDetails} value={salesPersonId === null ? -1 : salesPersonId}
                    onChange={onSalesPersonChanged}>
              <option value={-1}>- All -</option>
              {employeesList && employeesList.filter(onlySelectedEmployees).sort(alphabetically).map(employee => 
                <option key={employee['id']} value={employee['id']}>{employee['fullName']}</option>
              )}
            </select>
          </div>
        </div>
      </div>

      <div className={"form-section table-section"}>
        <div className={"data-table inquiry-details-table"}>
          <table>
            <thead>
            <tr>
              <th scope={"col"} className={"index-col-head"}>#</th>
              <th scope={"col"} style={{ minWidth: '140px' }}>
                Date
                <SortIndicator isAscending={false} />
              </th>
              <th scope={"col"} style={{ minWidth: '180px' }}>
                Sales Person
              </th>
              <th scope={"col"} style={{ minWidth: '220px' }}>
                Contact Name
                <SortIndicator isAscending={true} />
              </th>
              <th scope={"col"} style={{ minWidth: '140px' }}>
                Contact No
              </th>
              <th scope={"col"} style={{ minWidth: '200px' }}>
                City/Township
              </th>
              <th scope={"col"} style={{ minWidth: '80px' }}>
                Source
              </th>
              <th scope={"col"} style={{ minWidth: '180px' }}>
                Business Type
              </th>
              <th scope={"col"} style={{ minWidth: '80px' }}>
                Visit/Call
              </th>
              <th scope={"col"} style={{ minWidth: '240px' }}>
                Follow Up Action
              </th>
            </tr>
            </thead>
            <tbody>
              {isLoadingDetails() && <TableLoadingIndicator colspan={10} />}

              {!isLoadingDetails() && pageData.length === 0 &&
                <TableEmptyRow colSpan={10} />
              }

              {!isLoadingDetails() && pageData.length > 0 && 
                pageData.map((history, index) => 
                  <tr key={history['followUpEntryId']}>
                    <td className={"index-col"}>
                      {(DEFAULT_LIST_PAGE_SIZE * (currentPage - 1)) + (index + 1)}
                    </td>
                    <td>
                      <Moment date={history['followUpEntryDatetime']} format={formatters.datetimeShort} />
                    </td>
                    <td>
                      {history['followUpReporterName']}
                    </td>
                    <td>
                      {history['contactFullName']}
                    </td>
                    <td>
                      {history['contactNo']}
                    </td>
                    <td>
                      {getRegionDisplayByName(history['cityName'], history['townshipName'])}
                    </td>
                    <td>
                      {salesSources[history['source']]['code']}
                    </td>
                    <td>
                      <NullBlankValue value={history['businessTypeName']} />
                    </td>
                    <td>
                      {salesContactForms[history['contactForm']]}
                    </td>
                    <td>
                      {getFollowUpStageActionDisplay(history['followUpStage'], history['followUpAction'])}
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
          {detailResults && 
            <TablePaginationLocal fullData={detailResults} 
                                  setPageData={setPageData}
                                  currentPage={currentPage} setCurrentPage={setPage} />
          }
        </div>
      </div>
    </TabContentPane>
  )
}

const renderNullZeroNumber = (value) => {
  if (value === undefined || value === null) return '-';
  if (value === 0) return '-';
  return formatNumber(value, 0);
}
