import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { authService as authService2 } from "../../repos/apiServices2";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { inventoryServices } from "../../repos/apiServices";
import { customerServices as customerServices2 } from "../../repos/apiServices2";
import { navigableRoutes as routes } from "../../repos/constants";
import { role as roleConstants } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "../service/NullBlankField";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";
import { ServiceProfileStatus } from "../service/ServiceProfileStatus";

import '../shared/EntryForm.css';


const customerSources = refs.customer.source;

export const CustomerProfilePage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessTypeId, setBusinessTypeId] = useState(null);
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [address, setAddress] = useState("");
  const [contactFullName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [nrc, setNrc] = useState("");
  const [designation, setDesignation] = useState("");
  const [source, setSource] = useState(customerSources.unspecified);
  const [disableNewQuotationRequest, setDisableNewQuotationRequest] = useState(true);
  const [disableNewOrderConfirmation, setDisableNewOrderConfirmation] = useState(true);

  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);

  const [serviceProfile, setServiceProfile] = useState(null);
  const [locationsCount, setLocationsCount] = useState(0);
  const [generatorsCount, setGeneratorsCount] = useState(0);
  const [disableViewServiceProfile, setDisableViewServiceProfile] = useState(true);

  const [allowCustomerEdit, setAllowCustomerEdit] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalCustomerDetails, setOriginalCustomerDetails] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [serviceProfilesGranted, setServiceProfilesGranted] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    if (location.state === null) {
      navigate(routes.customersList.url);
    }

    let _customer = location.state;
    prepareCustomerDetails(_customer);

    fetchBusinessTypes();
    fetchUserPermissions();

    setIsLoading(true);
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const responseData = response['data'];
        let _quotationRequestPermissions = responseData['quotationRequests'];
        setDisableNewQuotationRequest(_quotationRequestPermissions['reviewLevel'] !== roleConstants.quotationRequest.reviewLevels.requester);

        let _orderConfirmationPermissions = responseData['orderConfirmations'];
        setDisableNewOrderConfirmation(_orderConfirmationPermissions['editLevel'] === roleConstants.orderConfirmation.editLevels.readOnly);

        let _servicePermissions = responseData['service'];
        setDisableViewServiceProfile(_servicePermissions['serviceProfilesGranted'] === false);
      })
      .then(() => {
        return inventoryServices.fetchCustomerServiceProfileStatus(_customer['id']);
      })
      .then((response) => {
        const _serviceProfile = response['data']['data'];
        setServiceProfile(_serviceProfile);
        setLocationsCount(_serviceProfile['summary']['locationsCount']);
        setGeneratorsCount(_serviceProfile['summary']['generatorsCount']);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const prepareCustomerDetails = (_customer) => {
    // NOTE(yemon): it's kinda inconsistent *not* to pull the customer details separately 
    // from a dedicated API route on page load, instead of relying on the location.state object
    // passed into from the listing. But I'm leaving it as it is intentionally for the time being.
    setCustomerId(_customer['id']);
    setCustomerName(_customer['customerName']);
    setCompanyName(_customer['companyName']);
    setBusinessTypeId(_customer['businessTypeId']);
    setBusinessTypeName(_customer['businessTypeName']);
    setAddress(_customer['address']);
    setContactName(_customer['contactFullName']);
    setContactNo(_customer['contactNo']);
    setNrc(_customer['nrc']);
    setDesignation(_customer['designation']);

    setSource(_customer['source']);
  }

  const fetchBusinessTypes = () => {
    setIsLoadingBusinessTypes(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response.data))
      .finally(() => setIsLoadingBusinessTypes(false));
  }

  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const _servicePermissions = response['data']['service'];
        setServiceProfilesGranted(_servicePermissions['serviceProfilesGranted']);
      });
    authService2.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const _customerPermissions = response['data']['customer'];
        setAllowCustomerEdit(_customerPermissions['allowProfileEdit']);
      });
  }
  //#endregion

  //#region Control handlers
  const onNewQuotationRequestClicked = (ev) => {
    ev.preventDefault();
    setTimeout(() => {
      const params = {
        'customerId': customerId, // identification on the receiving end
      }
      navigate(routes.quotationRequestEntry.url, { state: params });
    }, 200);
  }

  const onNewOrderConfirmationClicked = (ev) => {
    ev.preventDefault();
    setTimeout(() => {
      const params = {
        'customerId': customerId, // identification on the receiving end
      }
      navigate(routes.orderConfirmationEntry.url, { state: params });
    }, 200);
  }

  const onViewServiceProfileClicked = (ev) => {
    ev.preventDefault();
    if (!serviceProfile) {
      return;
    }
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfile['id'],
        }
      })
    }, 200);
  }

  const isFormControlsDisabled = () => {
    return isLoading || isSubmitting;
  }
  //#endregion

  //#region Control handlers; Customer edit
  const onEditClicked = (ev) => {
    setIsEditing(true);
    setOriginalCustomerDetails({
      'customerName': customerName,
      'companyName': companyName,
      'businessTypeId': businessTypeId,
      'businessTypeName': businessTypeName,
      'address': address,
      'contactFullName': contactFullName,
      'contactNo': contactNo,
      'nrc': nrc,
      'designation': designation,
    });
  }

  const isFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      customerName: [],
      contactFullName: [],
      contactNo: [],
    };
    if (!customerName || customerName.trim() === '') {
      _hasErrors = true;
      _formErrors.customerName = ["Customer name is required."];
    }
    if (!contactFullName || contactFullName.trim() === '') {
      _hasErrors = true;
      _formErrors.contactFullName = ["Contact name is required."];
    }
    if (!contactNo || contactNo.trim() === '') {
      _hasErrors = true;
      _formErrors.contactNo = ["Contact number is required."];
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareCustomerPayload = () => {
    return {
      'customer_name': customerName.trim(),
      'company_name': companyName ? companyName.trim() : null,
      'business_type_id': businessTypeId,
      'address': address ? address.trim() : null,
      'contact_fullname': contactFullName.trim(),
      'contact_no': contactNo.trim(),
      'contact_nrc': nrc ? nrc.trim() : null,
      'designation': designation ? designation.trim() : null,
      'employee_id': auth.getUserId()['eid'],
    }
  }

  const onSaveClicked = (ev) => {
    ev.preventDefault();
    if (!isFieldsValid()) {
      setHasErrors(true);
      setAlertType(alertTypes.error);
      setAlertMessage("Fix the indicated errors before submitting again.");
      setIsAlertVisible(true);
      return;
    }
    else {
      setHasErrors(false);
      setIsAlertVisible(false);
    }

    const payload = {
      'customer_id': customerId,
      ...prepareCustomerPayload(),
    };
    setIsSubmitting(true);
    customerServices2.updateCustomerDetails(payload)
      .then((response) => {
        setHasErrors(false);
        setOriginalCustomerDetails({});
        setIsEditing(false);
        setIsAlertVisible(false);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        let errorData = errorResponse['data'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            setAlertType(alertTypes.error);
            setAlertMessage(errorData['error']);
            setIsAlertVisible(true);
          }
          if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unknown server error occurred. Please contact the administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onCancelClicked = (ev) => {
    setIsEditing(false);
    setIsSubmitting(false);
    setHasErrors(false);
    setIsAlertVisible(false);

    if (!originalCustomerDetails) {
      return;
    }
    setCustomerName(originalCustomerDetails['customerName']);
    setCompanyName(originalCustomerDetails['companyName']);
    setBusinessTypeId(originalCustomerDetails['businessTypeId']);
    setBusinessTypeName(originalCustomerDetails['businessTypeName']);
    setAddress(originalCustomerDetails['address']);
    setContactName(originalCustomerDetails['contactFullName']);
    setContactNo(originalCustomerDetails['contactNo']);
    setNrc(originalCustomerDetails['nrc']);
    setDesignation(originalCustomerDetails['designation']);
  }

  const getUsageInfoSection = () => {
    if (!serviceProfile || !isEditing) {
      return <></>
    }

    return (
      <div className={"form-section"}>
        <WarningMessage>
          <b>This Customer has an active Service Profile.</b> Any updates here will be visible and reflected in that Service Profile as well.
        </WarningMessage>
      </div>
    )
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container-grid"}>
        <div className={"content-area content-area-main"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.customersList.displayShort} anchorTo={routes.customersList.url} />
              <BreadcrumbItem text={routes.customerProfile.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Customer Profile</h1>
          </div>

          {getUsageInfoSection()}

          <form autoComplete="off">
            <div className={"form-section"}>
              <div className={"entry-form"}>
                <div className={"form-label-wide"}>
                  <label>
                    Customer Name:<span className="error-message">*</span>
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      {customerName}
                    </ReadonlyField>
                  }
                  {isEditing &&
                    <>
                      <input type={"text"} id={"customerName"} className={"form-control"}
                            autoComplete={"off"} maxLength={100} disabled={isFormControlsDisabled()}
                            value={customerName} onChange={(ev) => setCustomerName(ev.target.value)} />
                      <FieldErrorMessage visible={hasErrors} message={formErrors["customerName"]} />
                    </>
                  }
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Company:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      <NullBlankValue value={companyName} />
                    </ReadonlyField>
                  }
                  {isEditing &&
                    <input type={"text"} id={"companyName"} className={"form-control"}
                          autoComplete={"off"} maxLength={100} disabled={isFormControlsDisabled()}
                          value={companyName} onChange={(ev) => setCompanyName(ev.target.value)} />
                  }
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Business Type:<span className="error-message">*</span>
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      {businessTypeName}
                    </ReadonlyField>
                  }
                  {isEditing &&
                    <select name={"businessTypeName"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                            value={businessTypeId} onChange={(ev) => setBusinessTypeId(parseInt(ev.target.value))}>
                      {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
                    </select>
                  }
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Address:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      <NullBlankValue value={address} />
                    </ReadonlyField>
                  }
                  {isEditing &&
                    <textarea id={"address"} className={"form-control"} rows={3} disabled={isFormControlsDisabled()}
                              value={address} onChange={(ev) => setAddress(ev.target.value)} maxLength={300}>
                    </textarea>
                  }
                </div>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>
                Contact Details
              </h2>
              <div className={"entry-form"}>
                <div className={"form-label-wide"}>
                  <label>
                    Contact Name:<span className="error-message">*</span>
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      {contactFullName}
                    </ReadonlyField>
                  }
                  {isEditing &&
                    <>
                      <input type={"text"} id={"contactName"} className={"form-control md-field"} 
                            autoComplete={"off"} disabled={isFormControlsDisabled()} maxLength={100}
                            value={contactFullName} onChange={(ev) => setContactName(ev.target.value)} />
                      <FieldErrorMessage visible={hasErrors} message={formErrors["contactFullName"]} />
                    </>
                  }
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Contact No:<span className="error-message">*</span>
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      {contactNo}
                    </ReadonlyField>
                  }
                  {isEditing &&
                    <>
                      <input type={"text"} id={"contactNo"} className={"form-control md-field"} 
                            autoComplete={"off"} disabled={isFormControlsDisabled()} maxLength={50}
                            value={contactNo} onChange={(ev) => setContactNo(ev.target.value)} />
                      <FieldErrorMessage visible={hasErrors} message={formErrors["contactNo"]} />
                    </>
                  }
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Contact NRC:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      <NullBlankValue value={nrc} />
                    </ReadonlyField>
                  }
                  {isEditing && 
                    <input type={"text"} id={"nrc"} className={"form-control md-field"} autoComplete={"off"}
                          disabled={isFormControlsDisabled()} maxLength={50}
                          value={nrc} onChange={(ev) => setNrc(ev.target.value)} />
                  }
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Contact Designation:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  {!isEditing &&
                    <ReadonlyField>
                      <NullBlankValue value={designation} />
                    </ReadonlyField>
                  }
                  {isEditing && 
                    <input type={"text"} id={"designation"} className={"form-control md-field"} autoComplete={"off"}
                          disabled={isFormControlsDisabled()} maxLength={50}
                          value={designation} onChange={(ev) => setDesignation(ev.target.value)} />
                  }
                </div>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>
                Profile Status
              </h2>
              <div className={"entry-form"}>
                <div className={"form-label-wide"}>
                  <label>
                    Created Source:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customerSources[source]}
                  </ReadonlyField>
                </div>
              </div>
            </div>

            {allowCustomerEdit &&
              <div className={"form-section-controls"}>
                {!isEditing &&
                  <button className={"btn btn-secondary"} onClick={onEditClicked}>
                    <i className={"fa-solid fa-pencil"}></i>Edit
                  </button>
                }
                {isEditing &&
                  <>
                    <button className={"btn btn-primary"} disabled={isFormControlsDisabled()}
                            onClick={onSaveClicked}>
                      {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                      {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                      Save
                    </button>
                    <button className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                            onClick={onCancelClicked}>
                      <i className={"fa-solid fa-xmark"}></i>Cancel
                    </button>
                  </>
                }
              </div>
            }

          </form>
        </div>

        <div className={"content-area content-area-side"}>
          <div className={"info-panel"}>
            <h2>Quick Actions</h2>
            <div className={"panel-controls"}>
              <button className="btn btn-md btn-secondary" onClick={onNewQuotationRequestClicked}
                      disabled={isLoading || disableNewQuotationRequest}>
                <i className="fa-solid fa-plus"></i>
                New Quotation Request
              </button>
            </div>
            <div className={"panel-controls"}>
              <button className="btn btn-md btn-secondary" onClick={onNewOrderConfirmationClicked}
                      disabled={isLoading || disableNewOrderConfirmation}>
                <i className="fa-solid fa-plus"></i>
                New Order Confirmation
              </button>
            </div>
          </div>

          <div className={"info-panel"}>
            <h2>Service Profile</h2>
            {!serviceProfile &&
              <div className={"panel-contents"}>
                No active service profile.
              </div>
            }
            {serviceProfile &&
              <div className={"panel-contents"}>
                <b>
                  <ServiceProfileStatus profileStatus={serviceProfile['status']}
                                        profileActiveDatetime={serviceProfile['activeDatetime']}
                                        profileInactiveDatetime={serviceProfile['inactiveDatetime']} />
                </b>
              </div>
            }
            {serviceProfile &&
              <div className={"panel-contents"}>
                Currently has <b>{generatorsCount}</b> service generator{generatorsCount > 1 ? "s": ""},
                commissioned at <b>{locationsCount}</b> {locationsCount > 1 ? "different" : ""} location{locationsCount > 1 ? "s": ""}.
              </div>
            }
            {serviceProfilesGranted &&
              <div className={"panel-controls"}>
                <button role={"button"} className={"btn btn-secondary"}
                        disabled={isLoading || disableViewServiceProfile} onClick={onViewServiceProfileClicked}>
                  <i className={"fa-solid fa-search"}></i>View
                </button>
              </div>
            }
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                    onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>

      </main>
    </MasterPageContainer>
)
//#endregion
}
