import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";

import './TablePagination.css';

export const TablePaginationLocal = ({ 
                                      fullData, setPageData,
                                      currentPage, setCurrentPage,
                                    }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (!fullData || fullData === null) {
      setTotalRecords(0);
      setTotalPages(0);
      return;
    }

    const _totalRecords = fullData.length;
    setTotalRecords(fullData.length);
    const _totalPages = calculateTotalPages(_totalRecords);
    setTotalPages(_totalPages);
    clampCurrentPage();

    const page = currentPage ? currentPage : 1;
    gotoPage(page, _totalRecords);
  }, [fullData]);

  const calculateTotalPages = (totalRecords) => {
    const _pageSize = DEFAULT_LIST_PAGE_SIZE;
    let _totalPages = 0;
    if (totalRecords > _pageSize) {
      _totalPages = Math.floor(totalRecords / _pageSize);
      const _tailCount = totalRecords % _pageSize;
      if (_tailCount > 0) {
        _totalPages += 1;
      }
    } 
    return _totalPages;
  }

  const clampCurrentPage = () => {
    setCurrentPage(Math.min(totalPages, currentPage));
  }

  // Array.slice(start, end) works as [startIndex:endIndex)
  const getOffsets = (page, totalRecords) => {
    const pageSize = DEFAULT_LIST_PAGE_SIZE;
    const start = Math.max(0, page-1) * pageSize;
    let end = 0;
    if (totalRecords < pageSize) {
      end = totalRecords;
    }
    else {
      for (end = start; end < Math.min(start+pageSize, totalRecords); end += 1) {
        // NOTE(yemon): Nothing here on purpose...
      }
    }

    return {
      start: start,
      end: end,
    }
  }

  const gotoFirstPage = () => {
    gotoPage(1, totalRecords);
  }

  const gotoPrevPage = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    gotoPage(page, totalRecords);
  }

  const gotoPage = (page, totalRecords) => {
    setCurrentPage(page);
    const offsets = getOffsets(page, totalRecords);
    const data = fullData.slice(offsets.start, offsets.end);
    setPageData(data);
  }

  const gotoNextPage = (fromPage) => {
    let page = Math.min(totalPages, fromPage + 1);
    gotoPage(page, totalRecords);
  }

  const gotoLastPage = () => {
    if (totalPages === 0) return;
    gotoPage(totalPages, totalRecords);
  }

  const shouldDisablePrevArrows = () => {
    if (totalPages === 0) return true;
    return currentPage === 1;
  }

  const shouldDisableNextArrows = () => {
    if (totalPages === 0) return true;
    return currentPage === totalPages;
  }

  if (!fullData || fullData.length === 0) {
    return (
      <></>
    )
  } else {
    return (
      <div className="table-pagination">
        <div className="pagination-settings">
        </div>
        <div className="pagination-summary">
          Page: {currentPage} of {totalPages} &bull; <b>Total record{totalRecords > 1 ? 's' : ''}: {totalRecords}</b>
        </div>
        <div className="pagination-controls">
          <div className="first-arrow">
            <button onClick={gotoFirstPage} disabled={shouldDisablePrevArrows()}>
              <i className="fa-solid fa-angles-left"></i>
            </button>
          </div>
          <div className="prev-arrow">
            <button onClick={ev => gotoPrevPage(currentPage)} disabled={shouldDisablePrevArrows()}>
              <i className="fa-solid fa-angle-left"></i>
            </button>
          </div>
          <div className="page-number">
            <select id={"page"} name={"page"} className={"page-number-dropdown"} disabled={totalPages === 0}
                    value={currentPage} onChange={(ev) => gotoPage(parseInt(ev.target.value), totalRecords)}>
              {totalPages > 0 ?
                [...Array(totalPages).keys()].map(pageIndex =>
                  <option key={pageIndex} value={pageIndex + 1}>{pageIndex + 1}</option>
                )
                : <option key={0} value={0}>-</option>
              }
            </select>
          </div>
          <div className="next-arrow">
            <button onClick={(ev) => gotoNextPage(currentPage)} disabled={shouldDisableNextArrows()}>
              <i className="fa-solid fa-angle-right"></i>
            </button>
          </div>
          <div className="last-arrow">
            <button onClick={gotoLastPage} disabled={shouldDisableNextArrows()}>
              <i className="fa-solid fa-angles-right"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }
}
