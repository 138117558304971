import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { setupServices } from "../../repos/apiServices2";
import { handleApiErrorAlert } from "../../repos/httpUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { InfoMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ErrorPrompt } from "../shared/PromptMessages";
import { PromptButton } from "../shared/PromptMessages";

import "./SystemEntryPage.css";

export const UserAccountSetupPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState(null);
  const [fullName, setFullName] = useState("");
  const [rankName, setRankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [username, setUsername] = useState("");
  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState(false);

  const [usages, setUsages] = useState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");
  const [showsUsagePrompt, setShowsUsagePrompt] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    const state = location.state;
    if (state && state['id']) {
      setIsEditing(true);
      const userId = state['id'];
      //fetchUserAccount(userId);
    }
  }, []);

  const fetchUserAccount = (userId) => {
    setIsLoading(false);
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container-grid"}>
        <div className={"content-area content-area-main"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.manageUsersAndPermissions.display} anchorTo={routes.manageUsersAndPermissions.url} />
              <BreadcrumbItem text={routes.userAccountsSetup.displayShort} anchorTo={routes.userAccountsSetup.url} />
              <BreadcrumbItem text={routes.userAccountsSetupEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>
              {routes.userAccountsSetupEntry.display}
            </h1>
          </div>

          <div className={"form-section"}></div>
        </div>

        <div className={"content-area content-area-side"}>
          <UserAccountSideWidget />
        </div>

        <PageAlert visible={isAlertVisible} type={alertType}
                   onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
          {alertMessage}
        </PageAlert>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const UserAccountSideWidget = ({}) => {
  return (
    <div className={"info-panel"}>
      <h2>(usagae?)</h2>

    </div>
  )
}