import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";

import "../reports/OptionsField.css";


export const EmployeeOptionsField = ({
                                      employeeBranchGroups, employeeRanks,
                                      selectedOptions, setSelectedOptions,
                                      disabled, 
                                    }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const onOpenClicked = () => {
    setIsModalOpened(true);
  }

  const onCloseClicked = () => {
    updateButtonLabel();
    setIsModalOpened(false);
  }

  const [totalEmployees, setTotalEmployees] = useState(0);

  useEffect(() => {
  }, []);

  useEffect(() => {
    let _total = 0;
    for (let branch of employeeBranchGroups) {
      _total += branch.employees.length;
    }
    setTotalEmployees(_total);
  }, [employeeBranchGroups]);

  useEffect(() => {
    updateButtonLabel();
  }, [selectedOptions])

  const [buttonLabel, setButtonLabel] = useState("Select...");

  const updateButtonLabel = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setButtonLabel("Select...");
    }
    else {
      setButtonLabel(`${selectedOptions.length} selected`)
    }
  }

  return (
    <div className={"options-field"}>
      <EmployeeOptionsModal isOpen={isModalOpened} onRequestClose={onCloseClicked} isLoading={disabled} 
                            employeeBranchGroups={employeeBranchGroups} employeeRanks={employeeRanks}
                            totalEmployees={totalEmployees}
                            selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />

      <button type={"button"} className={"btn btn-secondary"} disabled={disabled}
              title={"Click to choose employees to apply."}
              onClick={onOpenClicked}>
        {buttonLabel}
      </button>
    </div>
  )
}


const EmployeeOptionsModal = ({
                                isOpen, onRequestClose, isLoading, 
                                employeeBranchGroups, employeeRanks,
                                selectedOptions, setSelectedOptions,
                              }) => {
  const isSelected = (employeeId) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return false;
    }
    return selectedOptions.some((_employee) => _employee['id'] === employeeId);
  }

  const onSelectChecked = (ev, employee, checked) => {
    const alreadySelected = (_employee) => {
      return _employee['id'] === employee['id'];
    }

    let _selectedOptions = selectedOptions.slice();

    if (checked && !_selectedOptions.some(alreadySelected)) {
      _selectedOptions.push(
        composeSummaryResult(
          employee['id'], 
          employee['fullName'], 
          0, 0
        )
      );
      setSelectedOptions(_selectedOptions);
    }

    if (!checked && _selectedOptions.some(alreadySelected)) {
      let existingIndex = _selectedOptions.findIndex(alreadySelected);
      if (existingIndex !== -1) {
        _selectedOptions.splice(existingIndex, 1);
        setSelectedOptions(_selectedOptions);
      }
    }
  }

  const getDescription = () => {
    return `${selectedOptions.length} selected.`;
  }

  const [selectedFilterRankId, setSelectedFilterRankId] = useState(-1);

  const onFilterRankChanged = (ev) => {
    setSelectedFilterRankId(parseInt(ev.target.value));
  }

  const renderDescription = () => {
    return (
      <div>
        <span className={"inline-controls"}>
          Filter:<>&nbsp;</>
          <select id={"filter-ranks"} name={"filter-ranks"} className={"form-control form-select sm-field"}
                  disable={isLoading} value={selectedFilterRankId} onChange={onFilterRankChanged}>
            <option value={-1}>- All - </option>
            {employeeRanks && employeeRanks.length > 0 && 
              employeeRanks.map(rank => <option key={rank['id']} value={rank['id']}>{rank['name']}</option>)
            }
          </select>
        </span>
        <span>{selectedOptions.length} selected</span>
      </div>
    );
  }

  return (
    <ModalContainer elementId={"business-type-options-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose}
                    modalSize={modalSizes.tiny} height={720}
                    title={"Select Employees"}
                    shortDescription={renderDescription()}>
      <div className={"modal-data-table options-field-elements"}>
        <table>
          <thead>
          <tr>
            <th scope="col" className={"index-col-head fixed-width"}></th>
            <th scope="col">Name</th>
            <th scope="col">Rank</th>
          </tr>
          </thead>
          <tbody>
          {isLoading && employeeBranchGroups &&
            <TableLoadingIndicator colspan={3} />
          }

          {!isLoading && employeeBranchGroups && employeeBranchGroups.length > 0 &&
            employeeBranchGroups.map((employeeBranch, index) =>
              <EmployeeTableRow key={employeeBranch['id']} employeeBranch={employeeBranch} 
                                filteredRankId={selectedFilterRankId}
                                isSelected={isSelected} onCheckChanged={onSelectChecked} />
            )
          }

          {!isLoading && employeeBranchGroups && employeeBranchGroups.length === 0 &&
            <TableEmptyRow colSpan={3} />
          }
          </tbody>
        </table>
      </div>

      <div className={"options-field-margin"}></div>
    </ModalContainer>
  )
}

const EmployeeTableRow = ({
                            employeeBranch, filteredRankId,
                            isSelected, onCheckChanged,
                          }) => {
  const isFilteredByRank = (employee) => {
    if (filteredRankId === -1) {
      return true;
    }
    if (employee['rankId'] === filteredRankId) {
      return true;
    }
    return false;
  }

  return (
    <>
      <tr>
        <td className={"index-col"}></td>
        <td colSpan={2}>
          <b>{employeeBranch['name']}</b>
        </td>
      </tr>
      {employeeBranch['employees'].length > 0 && employeeBranch['employees'].filter(isFilteredByRank).map((employee, index) => 
        <tr className={"clickable-row"} key={employee['id']}
            onClick={(ev) => onCheckChanged(ev, employee, !isSelected(employee['id']))}>
          <td className={"index-col"}>
            <label htmlFor={`select-business-type-${employee['id']}`}>
              <input type={"checkbox"} id={`select-business-type-${employee['id']}}`}
                    name={`select-business-type-${employee['id']}}`}
                    title={`Select ${employee['fullName']}`}
                    checked={isSelected(employee['id'])}
                    onChange={(ev) => onCheckChanged(ev, employee, ev.target.checked)} />
            </label>
          </td>
          <td title={`Select ${employee['fullName']}`}>
            <label htmlFor={`select-business-type-${employee}`} className={"no-padding"}>
              {employee["fullName"]}
            </label>
          </td>
          <td>
            {employee['rankName']}
          </td>
        </tr>
      )}
    </>
  )
}

export const composeSummaryResult = (id, fullName, targetCall, targetVisit) => {
  return {
    'id': id,
    'fullName': fullName,

    'callLeads': null,
    'callProspects': null,
    'callQuotations': null,
    'callDeals': null,
    'callWins': null,
    'callLosts': null,
    'totalCalls': null,
    'visitLeads': null,
    'visitProspects': null,
    'visitQuotations': null,
    'visitDeals': null,
    'visitWins': null,
    'visitLosts': null,
    'totalVisits': null,
    'grandTotal': null,

    'targetCall': targetCall,
    'targetVisit': targetVisit,
    'callPercent': 0,
    'visitPercent': 0,
  };
}
