import React from "react";

import { reportTypes } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";

import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReportsListPageView } from "../reports/Reports";

export const InquiryRecordsReportsListPage = () => {
  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs>
        <BreadcrumbItem text={routes.salesReports.displayShort} anchorTo={routes.salesReports.url} />
        <BreadcrumbItem text={routes.salesReports_InquiryRecords.displayShort} isActive={true} />
      </Breadcrumbs>
    )
  }

  return (
    <ReportsListPageView reportType={reportTypes.sales.inquiryRecords}
                         entryPageUrl={routes.salesReports_InquiryRecordsReport.url}
                         listingTitle={routes.salesReports_InquiryRecords.display}
                         pageBreadcrumbs={renderBreadcrumbs()} />
  )
}
