import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { systemServices } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ControlsGrid } from "../shared/ControlsGrid";
import { ControlsLoadingIndicator } from "../shared/ControlsGrid";
import { ControlsEmptyIndicator } from "../shared/ControlsGrid";
import { ControlsGroup } from "../shared/ControlsGrid";
import { ControlsItems } from "../shared/ControlsGrid";
import { ControlButton } from "../shared/ControlsGrid";

import "../shared/ListingPage.css";

export const SalesReportsPage = () => {
  //#region States & Effects
  const navigate = useNavigate();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  
  const [allowSalesInquiriesGroup, setAllowSalesInquiryGroups] = useState(false);
  const [allowInquiryRecords, setAllowInquiryRecords] = useState(false);

  const [allowSalesPersonsGroup, setAllowSalesPersonsGroup] = useState(false);
  const [allowSalesPersonSummaries, setAllowSalesPersonSummaries] = useState(false);

  useEffect(() => {
    fetchReportPermissions();
  }, []);

  const fetchReportPermissions = () => {
    const userId = auth.getUserId();
    systemServices.postReportPermissions(userId)
      .then((response) => {
        const sales = response['data']['sales'];
        const _inquiryRecords = sales['inquiryRecords'];
        const _salesPersonSummaries = sales['salesPersonSummaries'];

        setAllowInquiryRecords(_inquiryRecords);
        setAllowSalesPersonSummaries(_salesPersonSummaries);
        if (_inquiryRecords) {
          setAllowSalesInquiryGroups(true);
        }
        if (_salesPersonSummaries) {
          setAllowSalesPersonsGroup(true);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //#endregion

  //#region Control handlers
  const isEmpty = () => {
    return !allowSalesInquiriesGroup && !allowSalesPersonsGroup;
  }

  const onClickInquiryRecord = (ev) => {
    navigate(routes.salesReports_InquiryRecords.url);
  }

  const onClickSalesPersonsSummaries = (ev) => {
    navigate(routes.salesReports_SalesPeronSummaries.url);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesReports.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.salesReports.displayShort}</h1>
          </div>

          <ControlsGrid isLoading={isLoading} isEmpty={isEmpty()} >
            {isLoading &&
              <ControlsLoadingIndicator />
            }

            {isEmpty() && <ControlsEmptyIndicator />}

            {allowSalesInquiriesGroup && 
              <>
                <ControlsGroup title={"Sales Inquiries"} />
                <ControlsItems>
                  {allowInquiryRecords && 
                    <ControlButton icon={routes.salesReports_InquiryRecords.faIcon}
                                  label={routes.salesReports_InquiryRecords.displayShort}
                                  disabled={false} onClick={onClickInquiryRecord} />
                  }
                </ControlsItems>
              </>
            }

            {allowSalesPersonsGroup && 
              <>
                <ControlsGroup title={"Sales Persons"} />
                <ControlsItems>
                  {allowSalesPersonSummaries &&
                    <ControlButton icon={routes.salesReports_SalesPeronSummaries.faIcon}
                                  label={routes.salesReports_SalesPeronSummaries.displayShort}
                                  disabled={false} onClick={onClickSalesPersonsSummaries} />
                  }
                </ControlsItems>
              </>
            }

          </ControlsGrid>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
