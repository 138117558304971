import { refs } from "./constants";

const warrantyHoursValidity = refs.inventory.serviceGeneratorWarrantyHoursValidity;
const warrantyStatuses = refs.inventory.serviceGeneratorWarrantyStatus;

export const isUnderWarrantyFromStatus = (warrantyStatus) => {
  if (!warrantyStatus) {
    return warrantyStatuses.null;
  }
  if (warrantyStatus.runHoursValidity === warrantyHoursValidity.unidentified) {
    return warrantyStatus.withinValidDate;
  }
  return warrantyStatus.withinValidDate && warrantyStatus.runHoursValidity === warrantyHoursValidity.under;
}

export const isUnderWarranty = (withinValidDate, runHoursValidity) => {
  if (runHoursValidity === warrantyHoursValidity.unidentified) {
    return withinValidDate;
  }
  return withinValidDate && runHoursValidity === warrantyHoursValidity.under;
}

export const getWarrantySpecifications = (warrantyHours, warrantyMonths) => {
  let specs = [];
  let label = "";
  if (warrantyHours) {
    label = "hour";
    if (warrantyHours > 1) {
      label += "s";
    }
    specs.push(`${warrantyHours} ${label}`);
  }
  if (warrantyMonths) {
    label = "month";
    if (warrantyMonths > 1) {
      label += "s";
    }
    specs.push(`${warrantyMonths} ${label}`);
  }
  return specs.join(", ");
}
