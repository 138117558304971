import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../auth/AuthProvider';
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { customerServices as customerServices2 } from "../../repos/apiServices2";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { CustomerListSearchPanel } from "./CustomerListSearchPanel";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "./CustomerListPage.css";


export const CustomerListPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [customerProfiles, setCustomerProfiles] = useState();
  const [pagination, setPagination] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.customerProfiles);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext !== null) {
      triggerSearchByContext(searchOptionsContext, paginationContext);
    }
    else {
      fetchCustomerProfiles(paginationContext ? paginationContext['currentPage'] : 1);
    }
    //resetSearchPanelFields();
  }, []);
  //#endregion

  //#region Utilities
  const prepareListPayload = (page) => {
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': 15,
      }
    }
  }

  const fetchCustomerProfiles = (page) => {
    resetListingStates();
    setIsLoading(true);

    let tableOptions = prepareListPayload(page);
    customerServices2.fetchCustomerProfiles(tableOptions)
      .then((response) => {
        let responseData = response['data'];
        updateListingStates(responseData);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsLoading(false));
  }

  const updateListingStates = (responseData) => {
    setCustomerProfiles(responseData);
    setPagination(responseData['pagination']);
    savePaginationContext(contexts.customerProfiles, responseData['pagination']);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);

    fetchCustomerProfiles(1);
  }

  const onRowClicked = (ev, customer) => {
    setTimeout(() => {
      navigate(routes.customerProfile.url, { state: customer });
    }, 100);
  }

  const resetListingStates = () => {
    setCustomerProfiles(null);
    setPagination(null);
  }

  const onPageClicked = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchCustomerProfiles(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchCustomerProfiles(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchCustomerProfiles(page);
    }
  }
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [searchFields, setSearchFields] = useState({});

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Control handlers; Search panel
  const onSearchToggleClicked = () => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.customerProfiles, _isOpen);
  }

  const validateAndPrepareSearchOptions = () => {
    // NOTE(yemon): There isn't really anything to validate for now...
    return searchFields;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;

    let searchOptionsContext = {
      ...searchOptions,
    };
    saveSearchPanelOptionsContext(contexts.customerProfiles, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'summarize_generators': true,
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    };
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);
    setIsLoading(true);

    customerServices2.fetchCustomerProfiles(listPayload)
      .then((response) => {
        setIsSearching(true);
        const responseData = response['data'];
        updateListingStates(responseData);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.customerProfiles);
    fetchCustomerProfiles(1);
    resetSearchPanelFields();
  }

  const resetSearchPanelFields = () => {
    setSearchFields({
      'customer_name': '',
      'business_type_id': -1,
      'source_id': -1,
    });
  }

  const onCustomerNameSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'customer_name': ev.target.value,
    });
  }

  const onBusinessTypeSearchChanged = (businessTypeId) => {
    setSearchFields({
      ...searchFields,
      'business_type_id': businessTypeId,
    });
  }

  const onSourceSearchChanged = (sourceId) => {
    setSearchFields({
      ...searchFields,
      'source_id': sourceId,
    });
  }
  //#endregion

  //#region View Settings modal; handlers and utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    }
    else {
      fetchCustomerProfiles(1);
    }
  }
  //#endregion

  //#region Listing view context
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.customerProfiles);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.customerProfiles);
    if (searchOptions === null) {
      return null;
    }

    setSearchFields({
      'customer_name': searchOptions['customer_name'],
      'business_type_id': searchOptions['business_type_id'],
      'source_id': searchOptions['source_id'],
    });

    return searchOptions;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.customersList.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Customer Profiles</h1>
            <div className={"listing-controls"}>
              <button className={"btn btn-secondary"} disabled={isLoading} onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading || isSearchLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          <CustomerListSearchPanel isSearchOpen={isSearchOpen} isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                   searchFields={searchFields}
                                   onCustomerNameChanged={onCustomerNameSearchChanged}
                                   onBusinessTypeChanged={onBusinessTypeSearchChanged}
                                   onSourceChanged={onSourceSearchChanged}
                                   onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                   hasErrors={hasSearchErrors} errorMessage={searchErrorMessage} />

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th>Customer Name</th>
                <th>Company Name</th>
                <th>Business Type</th>
                <th>Contact Name</th>
                <th>Source</th>
              </tr>
              </thead>

              <tbody>
              {isLoading && <TableLoadingIndicator colspan={6} />}

              {!isLoading && customerProfiles && customerProfiles['data'].length > 0 &&
                customerProfiles['data'].map((customer, index) =>
                  <CustomerProfileRow key={customer['id']} customer={customer} index={index}
                                      currentPage={customerProfiles['pagination']['currentPage']} pageSize={customerProfiles['pagination']['pageSize']}
                                      onRowClicked={(ev) => onRowClicked(ev, customer)}
                  />
                )
              }

              {!customerProfiles || (customerProfiles['data'].length === 0 && !isLoading &&
                <TableEmptyRow colSpan={6} />
              )}
              </tbody>
            </table>

            {customerProfiles && pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={DEFAULT_LIST_PAGE_SIZE}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClicked}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.customerProfiles}
                               onViewSettingsSaved={onViewSettingsSaved}
              />
            }
          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


export const CustomerProfileRow = ({
                                     customer, index,
                                     currentPage, pageSize,
                                     onRowClicked,
                                   }) => {
  return (
    <>
      <tr>
        <td className={"index-col"}>{(pageSize * (currentPage - 1) + (index + 1))}</td>
        <td>
          <a href="#" role={"button"} className={"record-link"} onClick={(ev) => onRowClicked(ev, customer)}>
            {customer['customerName']}
          </a>
        </td>
        <td>
          {customer['companyName'] ? customer['companyName'] : '-'}
        </td>
        <td>{customer['businessTypeName']}</td>
        <td>{customer['contactFullName']}</td>
        <td>{refs.customer.source[customer['source']]}</td>
      </tr>
    </>
  )
}
